import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { PersonaSchema, SurveyResponseType } from '../../utils/interfaces';
import { savePersonaReport } from '../../api/firestore';
import { generateCoreValueVector, generatePersonaDescription, generatePersonalityTraitVector, generatePreferenceVector } from '../../api/api';
import PersonaComponent from './PersonaComponent';
import ProfileDialog from './ProfileDialog';
import ProfileSurveyAnswers from './ProfileSurveyAnswers';
import { logEvent } from '../../utils/logging';
import { parseSurveyDataJSONToString } from '../../utils/utils';

const ProfileComponent: React.FC = () => {
    const { user, isAuthenticated, completedSurveys, userPersona } = useAuth();
    const [isGeneratingPersona, setIsGeneratingPersona] = useState(false);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState<SurveyResponseType | null>(null);
    const [personaReport, setPersonaReport] = useState<PersonaSchema | null>(null);

    useEffect(() => {
        if (user != null && isAuthenticated() && userPersona != null) {
            setPersonaReport(userPersona);
        }
    }, [user, isAuthenticated, userPersona]);

    useEffect(() => {
        const generate = async () => {
            if (!isGeneratingPersona) return;
            if (!user || !completedSurveys) return;
            try {
                const dataArray = completedSurveys.map((surveyResult) => surveyResult.data);
                const query = parseSurveyDataJSONToString(dataArray);
                const [
                    personalityTraitVector,
                    coreValueVector,
                    preferenceVector,
                    personaDescription,
                ] = await Promise.all([
                    generatePersonalityTraitVector(query),
                    generateCoreValueVector(query),
                    generatePreferenceVector(query),
                    generatePersonaDescription(query),
                ]);
                setPersonaReport({ ...personaDescription, personalityTraitVector, coreValueVector, preferenceVector })
                await savePersonaReport(user.uid, personalityTraitVector, coreValueVector, preferenceVector, personaDescription);
                logEvent({
                    category: "Profile",
                    action: "Save Persona",
                    label: personaDescription.title,
                })
            } catch (error: any) {
                console.error('error', error);
                setError(error.message);
                logEvent({
                    category: "Profile",
                    action: "Save Persona Fail",
                })
            } finally {
                setIsGeneratingPersona(false);
            }
        }
        generate();
    }, [isGeneratingPersona, completedSurveys, user])

    function handleSubmit() {
        setIsGeneratingPersona(true);
        setError('');
        logEvent({
            category: "Profile",
            action: "Click Analyze Persona",
        })
    }

    function handleShowSurveyContent(survey: SurveyResponseType) {
        setSelectedSurvey(survey);
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            flexGrow={1}
            sx={{
                width: "100%",
                height: "100vh",
            }}
        >
            {/* Persona */}
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="100%">
                <PersonaComponent isLoading={isGeneratingPersona} personaReport={personaReport} error={error} hasSurveyResults={completedSurveys.length > 0} onSubmit={handleSubmit} />
            </Box>
            <Divider variant="middle" sx={{ height: '5px', width: '90%' }} />
            {/* Survey Answer Cards */}
            <ProfileSurveyAnswers surveyResults={completedSurveys} handleShowSurveyContent={handleShowSurveyContent} />
            {/* Survey Answer Dialog */}
            <ProfileDialog selectedSurveyResult={selectedSurvey} openDialog={openDialog} handleCloseDialog={handleCloseDialog} />
        </Box >
    )
}

export default ProfileComponent;
