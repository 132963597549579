import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { PersonaSchema } from '../../utils/interfaces';
import CountdownClock from '../CountdownClock';
import CoreValueChart from '../charts/CoreValueChart';
import CircularProgressWithText from '../CircularProgressWithText';
import PersonalityTraitVectorChart from '../charts/PersonalityTraitVectorChart';
import PersonaDescription from './PersonaDescription';
import { ArrowForwardIos } from '@mui/icons-material';
import PreferencesChart from '../charts/PreferencesChart';
import { useTranslation } from 'react-i18next';

interface PersonaComponentProps {
    isLoading: boolean,
    personaReport: PersonaSchema | null,
    error: string,
    hasSurveyResults: boolean,
    onSubmit: () => void,
}

const PersonaComponent: React.FC<PersonaComponentProps> = ({ isLoading, personaReport, error, hasSurveyResults, onSubmit }) => {
    const { t } = useTranslation();
    if (isLoading) {
        return (
            <Box display="flex" flexDirection="column" height="60vh" justifyContent="center" alignItems="center">
                <CircularProgressWithText />
            </Box>
        )
    }

    // when user did not answer any survey
    if (!hasSurveyResults) {
        return (
            <Box display="flex" flexDirection="column" height="80vh" justifyContent="center" alignItems="center">
                <Typography component="div" variant="caption" sx={{ p: 2 }} >{t('ProfilePage_NoSurvey')}</Typography>
                <Box>
                    <Button variant='contained' href='/' sx={{ m: 2, borderRadius: 5 }}> {t('ProfilePage_CompleteSurvey')} </Button>
                </Box>
            </Box>
        )
    }

    // when user encountered error
    if (error !== '') {
        return (
            <Box display="flex" flexDirection="column" height="80vh" justifyContent="center" alignItems="center">
                <Typography variant="caption" color="error" sx={{ p: 2 }}> {error}</Typography>
                <Box>
                    <Button variant="contained" color="warning" onClick={() => onSubmit()} sx={{ m: 2, borderRadius: 5 }}> {t('TryAgain')} </Button>
                </Box>
            </Box>
        )
    }

    // when user did not generate persona yet (or has any missing data);
    if (personaReport == null) {
        return (
            <Box display="flex" flexDirection="column" height="80vh" justifyContent="center" alignItems="center">
                <Typography maxWidth="300px" variant="caption" sx={{ p: 2 }}> {t('ProfilePage_NoPersona')} </Typography>
                <Box>
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{ borderRadius: 8, p: 2, border: 'hidden', backgroundImage: 'linear-gradient(to bottom right, #63CFB160 0%, #2B9C32C0 100%)', color: 'white', minWidth: '200px' }}
                        onClick={() => {
                            onSubmit();
                        }}>
                        {t('ProfilePage_AnalyzePersona')} <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
                    </Button>
                </Box>
            </Box>
        )
    }

    const oneMinuteInMilliseconds = 1 * 60 * 1000;
    const oneMinuteLater = new Date(new Date(personaReport.timestamp ?? new Date().getTime()).getTime() + oneMinuteInMilliseconds);
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ p: 3, maxWidth: '900px' }}>
            <Box margin={2}>
                <Typography variant="h3" fontFamily="'Fredericka the Great', cursive;">
                    {personaReport.title}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <CoreValueChart coreValueVector={personaReport.coreValueVector} sort={true} shuffle={false} maxCount={6} width="160px" height="160px" fontSize={6} />
                <PersonalityTraitVectorChart personalityTraitVector={personaReport.personalityTraitVector} width="180px" height="180px" />
            </Box>
            <PreferencesChart preferenceVector={personaReport.preferenceVector} />
            <PersonaDescription personaReport={personaReport} />
            <CountdownClock targetTimestamp={oneMinuteLater} actionComponent={<Button variant="contained" color="secondary" onClick={() => onSubmit()} sx={{ m: 2, p: 1.5, borderRadius: 5 }}> {t('ProfilePage_AnalyzeAgain')} <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} /> </Button>} />
        </Box >
    )
}

export default PersonaComponent;