import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { SurveyResponseType, PersonaSchema, UserInfo, EvaluationVector, PersonaDescriptionType, SurveyEvaluationType, SurveyKey } from '../utils/interfaces';

// Function to save survey answers for a given user
export const saveSurveyResults = async (uid: string, surveyKey: SurveyKey, surveyEvaluation: SurveyEvaluationType, evaluationVector: EvaluationVector, user_input_data: string): Promise<void> => {
    try {
        // Get a reference to the /survey-results/{uid} path
        const surveyResultsCollectionRef = doc(db, 'surveys', uid, 'results', surveyKey);
        // Prepare data to be saved
        const dataToSave = {
            ...surveyEvaluation,
            key: surveyKey,
            evaluationVector,
            timestamp: new Date().toString(),
            data: user_input_data
        } as SurveyResponseType;
        // Save data to the specified location in Firestore
        await setDoc(surveyResultsCollectionRef, dataToSave);
    } catch (error) {
        console.error("Error saving survey answers: ", error);
        throw new Error(`Something wrong happened. Please try again.`);
    }
};

export const savePersonaReport = async (uid: string, personalityTraitVector: EvaluationVector, coreValueVector: EvaluationVector, preferenceVector: EvaluationVector, personaDescription: PersonaDescriptionType): Promise<void> => {
    try {
        const userPersonaRef = doc(db, "persona", uid);
        await setDoc(userPersonaRef, {
            title: personaDescription.title,
            description: personaDescription.description,
            quotes: personaDescription.quotes,
            personalityTraitVector,
            coreValueVector,
            preferenceVector,
            timestamp: new Date().toString(),
        } as PersonaSchema);
    } catch (error) {
        console.error("Error saving personality report: ", error);
    }
};

export const readPersonaReport = async (uid: string): Promise<PersonaSchema | null> => {
    try {
        const userPersonaRef = doc(db, "persona", uid);
        const userPersonaDoc = await getDoc(userPersonaRef);
        if (userPersonaDoc.exists()) {
            const data = userPersonaDoc.data() as PersonaSchema;
            return data;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error retrieving persona report: ", error);
        return null;
    }
};

export const readUserInfo = async (uid: string): Promise<UserInfo | null> => {
    try {
        const userInfoRef = doc(db, 'users', uid);
        const userInfo = await getDoc(userInfoRef);
        if (userInfo.exists()) {
            const data = userInfo.data();
            return {
                userId: data.userId,
                displayName: data.displayName,
                birthday: data.birthday,
                gender: data.gender,
                country: data.country,
                created: data.created,
                lastActive: data.lastActive,
            } as UserInfo;
        } else {
            // console.error('User not found');
            return null;
        }
    } catch (error) {
        console.error('Error retrieving user information: ', error);
        return null;
    }
};

export const saveUserInfo = async (userInfo: UserInfo) => {
    const { userId, displayName, birthday, gender, country, created, lastActive } = userInfo;
    const userInfoRef = doc(db, "users", userId);
    try {
        await setDoc(userInfoRef, {
            userId,
            displayName,
            birthday,
            gender,
            country,
            created,
            lastActive,
        });
    } catch (error) {
        console.error('Error saving user information: ', error);
    }
}