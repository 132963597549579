import { EvaluationVector, PersonaDescriptionType, SurveyDataType, SurveyEvaluationType } from "../utils/interfaces";

export const getSurveyCollection = async (): Promise<SurveyDataType[]> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/surveys`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const responseData = await response.json();
        if (response.status !== 200) {
            throw responseData.error || new Error(`Request failed with status ${response.status}`);
        }
        const jsonData = responseData.result as SurveyDataType[];
        return jsonData;
    } catch (error: any) {
        if (error.message) {
            throw error;
        }
        console.error(error);
        throw new Error(`Error: ${error.message}`);
    }
}

export const generateSurveyEvaluation = async (surveyData: SurveyDataType, user_input: string): Promise<SurveyEvaluationType> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/generateSurveyResult`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_input,
                survey_key: surveyData.key,
            }),
        });
        const responseData = await response.json();
        if (response.status !== 200) {
            throw responseData.error || new Error(`Request failed with status ${response.status}`);
        }
        // console.log('generateSurveyResult', responseData.result);
        return responseData.result as SurveyEvaluationType;
    } catch (error: any) {
        if (error.message) {
            throw error;
        }
        console.error(error);
        throw new Error(`Something wrong happened while generating. Please try again.`);
    }
};

export const generateSurveyEvaluationVector = async (surveyData: SurveyDataType, data: string): Promise<EvaluationVector> => {
    let endpoint = '';
    if (surveyData.type === 'personality_trait') {
        endpoint = `${process.env.REACT_APP_API_ADDRESS}/api/generatePersonalityTraitVector`;
    } else if (surveyData.type === 'core_value') {
        endpoint = `${process.env.REACT_APP_API_ADDRESS}/api/generateCoreValueVector`;
    } else if (surveyData.type === 'preference') {
        endpoint = `${process.env.REACT_APP_API_ADDRESS}/api/generatePreferenceVector`;
    }
    if (endpoint === '') {
        throw new Error(`Error: has wrong survey data type: ${surveyData.type}`);
    }
    try {
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data,
            }),
        });
        const responseData = await response.json();
        if (response.status !== 200) {
            throw responseData.error || new Error(`Request failed with status ${response.status}`);
        }
        // console.log('generateSurveyEvaluationVector: ', responseData.result);
        const result = JSON.parse(responseData.result) as EvaluationVector;
        return result;
    } catch (error: any) {
        if (error.message) {
            throw error;
        }
        console.error(error);
        throw new Error(`Error: ${error.message}`);
    }
}

export const generatePersonalityTraitVector = async (query: string, callback?: React.Dispatch<React.SetStateAction<any>>): Promise<EvaluationVector> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/generatePersonalityTraitVector`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: query,
            }),
        });
        const responseData = await response.json();
        if (response.status !== 200) {
            throw responseData.error || new Error(`Request failed with status ${response.status}`);
        }
        // console.log('generatePersonalityTraitVector: ', responseData.result);
        const result = JSON.parse(responseData.result) as EvaluationVector;
        if (callback) {
            callback(result);
        }
        return result;
    } catch (error: any) {
        if (error.message) {
            throw error;
        }
        console.error(error);
        throw new Error(`Error: ${error.message}`);
    }
};

export const generateCoreValueVector = async (query: string, callback?: React.Dispatch<React.SetStateAction<any>>): Promise<EvaluationVector> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/generateCoreValueVector`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: query,
            }),
        });
        const responseData = await response.json();
        if (response.status !== 200) {
            throw responseData.error || new Error(`Request failed with status ${response.status}`);
        }
        // console.log('generateCoreValueVector: ', responseData.result);
        const result = JSON.parse(responseData.result) as EvaluationVector;
        if (callback) {
            callback(result);
        }
        return result;
    } catch (error: any) {
        if (error.message) {
            throw error;
        }
        console.error(error);
        throw new Error(`Error: ${error.message}`);
    }
};

export const generatePreferenceVector = async (query: string, callback?: React.Dispatch<React.SetStateAction<any>>): Promise<EvaluationVector> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/generatePreferenceVector`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: query,
            }),
        });
        const responseData = await response.json();
        if (response.status !== 200) {
            throw responseData.error || new Error(`Request failed with status ${response.status}`);
        }
        // console.log('generateCoreValueVector: ', responseData.result);
        const result = JSON.parse(responseData.result) as EvaluationVector;
        if (callback) {
            callback(result);
        }
        return result;
    } catch (error: any) {
        if (error.message) {
            throw error;
        }
        console.error(error);
        throw new Error(`Error: ${error.message}`);
    }
};

export const generatePersonaDescription = async (query: string, callback?: React.Dispatch<React.SetStateAction<any>>): Promise<PersonaDescriptionType> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/generatePersonaDescription`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: query,
            }),
        });
        const responseData = await response.json();
        if (response.status !== 200) {
            throw responseData.error || new Error(`Request failed with status ${response.status}`);
        }
        // console.log('generatePersonaDescription: ', responseData.result);
        const result = JSON.parse(responseData.result) as PersonaDescriptionType;
        if (callback) {
            callback(result);
        }
        return result;
    } catch (error: any) {
        if (error.message) {
            throw error;
        }
        console.error(error);
        throw new Error(`Error: ${error.message}`);
    }
};