import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

let ipAddress: string | undefined;

// export type UaEventOptions = {
//     action: string;
//     category: string;
//     label?: string;
//     value?: number;
//     nonInteraction?: boolean;
//     transport?: ('beacon' | 'xhr' | 'image');
// };

// Example: User Sign Up
// Category - "Authentication"
// Action - "Sign Up"
// Label - null
// Value - null
export const logEvent = async (props: UaEventOptions) => {
    if (process.env.NODE_ENV === 'production') {
        // Send Google Analytics Event
        ReactGA.event(props);
        // Send Facebook Event
        sendEventFB(props.action);
    } else {
        console.log('logged event', props);
    }
};

const getIpAddress = async () => {
    if (!ipAddress) {
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipResponse.json();
        ipAddress = ipData.ip;
    }
    return ipAddress;
};


const sendEventFB = async (event_name: string, custom_data?: any) => {
    try {
        const ipAddress = await getIpAddress();
        const userAgent = navigator.userAgent;
        const fbp = document.cookie.match('(^|;)\\s*fbp\\s*=\\s*([^;]+)')?.pop() || '';

        const response = await fetch(`https://graph.facebook.com/v16.0/2618962298380840/events?access_token=${process.env.REACT_APP_FB_TOKEN}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "data": [
                    {
                        "event_name": event_name,
                        "event_time": Math.floor(new Date().getTime() / 1000),
                        "action_source": "website",
                        "user_data": {
                            client_ip_address: ipAddress,
                            client_user_agent: userAgent,
                            fbp: fbp,
                        },
                        "custom_data": custom_data,
                    }
                ]
            }),
        });

        const data = await response.json();

        if (response.status !== 200) {
            throw data.error || new Error(`Request failed with status ${response.status}`);
        }

        return data;
    } catch (error: any) {
        console.error(error);
        throw new Error(`Something wrong happened while generating. Please try again.`);
    }
};