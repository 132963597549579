import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { SurveyQuestionType } from '../../utils/interfaces';
import { logEvent } from '../../utils/logging';

type SurveyQuestionProps = {
    question: SurveyQuestionType;
    currentIndex: number;
    selectedAnswer: string;
    onAnswered: (index: number, answer: string) => void;
    onSubmit: () => void;
    onNext: () => void;
    onPrev: () => void;
    isFirst: boolean;
    isLast: boolean;
};

const StyledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 10,
    top: 0,
    maxWidth: '700px',
    overflow: 'auto',
});

const SurveyQuestion: React.FC<SurveyQuestionProps> = ({ question, currentIndex, selectedAnswer, onAnswered, onSubmit, onNext, onPrev, isFirst, isLast }) => {
    const [selectedOption, setSelectedOption] = useState(selectedAnswer ?? '');

    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
        onAnswered(currentIndex, option);
    };

    const handleNextClick = () => {
        setSelectedOption('');
        onNext();
    };

    const handlePrevClick = () => {
        setSelectedOption('');
        onPrev();
    };

    const handleSubmit = () => {
        setSelectedOption('');
        onSubmit();
        logEvent({
            category: "Survey",
            action: "Click Submit Survey",
        })
    }

    const nextDisabled = (selectedOption === '' || selectedOption == null) && selectedAnswer == null;

    useEffect(() => {
        const handleKeyDown = (e: any) => {
            if (e.key === 'ArrowLeft' && !isFirst) {
                handlePrevClick();
            } else if (e.key === 'ArrowRight' && (!nextDisabled || isLast)) {
                if (isLast) {
                    handleSubmit();
                } else {
                    handleNextClick();
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirst, isLast, nextDisabled]);

    return (
        <StyledContainer>
            {/* content */}
            <Box display="flex" flexGrow={2} flexDirection="column" sx={{}}>
                {/* Question */}
                <Box sx={{ p: 2.5 }} >
                    <Typography variant="h6" component="p">{question.question}</Typography>
                </Box>
                {/* Options */}
                <Box justifyContent="center" alignItems="center" sx={{ display: 'flex', flexDirection: 'column' }}>
                    {Object.keys(question.options).map((option) => (
                        <Button
                            key={option}
                            variant={option === selectedAnswer || option === selectedOption ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => handleOptionClick(option)}
                            sx={{ m: 1, p: 1, width: '90%', borderRadius: 2 }}
                        >
                            <Typography variant="subtitle1">
                                {question.options[option]}
                            </Typography>
                        </Button>
                    ))}
                </Box>
                {/* Navigations */}

            </Box>
            {/* footer */}
            <Box display="flex" flexGrow={1} justifyContent="space-between" sx={{ m: 2, mb: 10 }}>
                <Button color="secondary" variant="outlined" onClick={handlePrevClick} disabled={isFirst}>
                    <ArrowLeft />
                </Button>
                <Button color="secondary" variant="outlined" onClick={isLast === true ? handleSubmit : handleNextClick} disabled={nextDisabled}>
                    {isLast === true ? 'Submit' : <ArrowRight />}
                </Button>
            </Box>
        </StyledContainer >
    );
};

export default SurveyQuestion;
