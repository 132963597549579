import React, { useEffect, useState } from 'react';
import { Home, Menu, Logout, Language, AccountCircleOutlined, PeopleAltOutlined, Login } from '@mui/icons-material';
import { Box, Drawer, Divider, IconButton, List, ListItem, ListItemIcon, Typography, Button, Modal } from '@mui/material';
import ROUTES from '../utils/Routes'; // Import your routes
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../utils/logging';

const DrawerNavigationBar: React.FC = () => {
    const { user, isAuthenticated, logout } = useAuth();
    const [openDrawer, setOpenDrawer] = useState(false);
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [openLanguageChangeModal, setOpenLanguageChangeModal] = useState(false);

    useEffect(() => {
        setOpenDrawer(false);
    }, [location]);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
        logEvent({
            category: "Naviation",
            action: "Click Open Navigation",
        })
    };

    const handleDrawerClose = (destination: string) => {
        setOpenDrawer(false);
        logEvent({
            category: "Naviation",
            action: "Click Navigation Item",
            label: destination,
        })
    };

    const handleLogout = async () => {
        if (user && isAuthenticated()) {
            try {
                await logout();
                logEvent({
                    category: "Authentication",
                    action: "Log Out",
                })
            } catch (error) {
                console.error('Error logging out:', error);
            }
        }
    };

    const languageModalBody = (
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }}
        >
            <Typography id="modal-modal-title" variant="h6" component="h2" p={1}>
                {t("ChangeLanguage")}
            </Typography>
            <Box>
                <Button variant="outlined" sx={{ m: 1 }} onClick={() => { i18n.changeLanguage('en'); setOpenLanguageChangeModal(false); handleDrawerClose('language'); }}>English</Button>
                <Button variant="outlined" sx={{ m: 1 }} onClick={() => { i18n.changeLanguage('ko'); setOpenLanguageChangeModal(false); handleDrawerClose('language'); }}>한국어</Button>
            </Box>
        </Box>
    );

    const isLoggedIn = user && isAuthenticated();

    return (
        <Box sx={{}}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2 }}
            >
                <Menu color="primary" />
            </IconButton>
            <Drawer
                anchor="right"
                open={openDrawer}
                onClose={() => handleDrawerClose('Close')}
            >
                <Box sx={{ width: 250 }}>
                    <Typography variant="h6" color="primary" sx={{ m: 2, ml: 2 }}>
                        {user?.displayName ?? user?.email?.split('@')[0] ?? 'Welcome'} {user ? <Typography variant="caption">({user.uid.slice(0, 5)})</Typography> : null}
                    </Typography>
                    <Divider />
                    <List>
                        {/* Home */}
                        <ListItem sx={{ p: 2 }} color="primary" key={'main'}>
                            <Link
                                to={ROUTES.main.path}
                                onClick={() => handleDrawerClose('Home')}
                                style={{
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <ListItemIcon>
                                    <Home color="primary" />
                                </ListItemIcon>
                                <Typography color="primary"> {t("Home")} </Typography>
                            </Link>
                        </ListItem>
                        {/* Profile */}
                        <ListItem sx={{ p: 2 }} color="primary" key={'profile'}>
                            <Link
                                to={ROUTES.profile.path}
                                onClick={() => handleDrawerClose('Profile')}
                                style={{
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <ListItemIcon>
                                    <AccountCircleOutlined color="primary" />
                                </ListItemIcon>
                                <Typography color="primary"> {t("Profile")} </Typography>
                            </Link>
                        </ListItem>
                        {/* Friends */}
                        <ListItem sx={{ p: 2 }} color="primary" key={'friends'}>
                            <Link
                                to={ROUTES.friends.path}
                                onClick={() => handleDrawerClose('Friends')}
                                style={{
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <ListItemIcon>
                                    <PeopleAltOutlined />
                                </ListItemIcon>
                                <Typography color="primary"> {t("Friends")} </Typography>
                            </Link>
                        </ListItem>
                        {/* Language */}
                        <ListItem sx={{ p: 2 }} color="primary" key='language' component="a" onClick={() => setOpenLanguageChangeModal(true)}>
                            <ListItemIcon>
                                <Language />
                            </ListItemIcon>
                            <Typography color="primary"> {t("Language")} </Typography>
                        </ListItem>
                        {/* Log In / Log Out */}
                        {isLoggedIn
                            ? <ListItem sx={{ p: 2 }} color="primary" key='logout' component="a" href={ROUTES.main.path} onClick={() => {
                                handleDrawerClose('Logout');
                                handleLogout();
                            }}>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <Typography color="primary"> {t("LogOut")} </Typography>
                            </ListItem>
                            : <ListItem sx={{ p: 2 }} color="primary" key='login'>
                                <Link
                                    to={ROUTES.profile.path}
                                    onClick={() => handleDrawerClose('Login')}
                                    style={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <ListItemIcon>
                                        <Login />
                                    </ListItemIcon>
                                    <Typography color="primary"> {t("LogIn")} </Typography>
                                </Link>
                            </ListItem>}
                    </List>
                    <Modal
                        open={openLanguageChangeModal}
                        onClose={() => setOpenLanguageChangeModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        {languageModalBody}
                    </Modal>
                </Box>
            </Drawer >
        </Box >
    );
};

export default DrawerNavigationBar;
