import { useState } from 'react';
import { styled } from '@mui/material/styles';
import SurveyQuestion from '../components/survey/SurveyQuestion';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../utils/Routes';
import { Box, LinearProgress, Typography } from '@mui/material';
import { SurveyKey } from '../utils/interfaces';
import { useSurveyCollectionData } from '../context/SurveyCollectionDataContext';

const StyledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20,
    overflow: 'auto',
});

function SurveyQuestionsPage() {
    const navigate = useNavigate();
    const params = useParams();
    const surveyName = params.type as SurveyKey;
    const { surveyCollectionData } = useSurveyCollectionData();
    const survey = surveyCollectionData.find((survey) => survey.key === surveyName);
    const [answers, setAnswers] = useState<string[]>(new Array(survey?.questions.length));
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleAnswered = (index: number, answer: string) => {
        setAnswers((prevAnswers) => {
            const newAnswers = [...prevAnswers];
            newAnswers[index] = answer;
            return newAnswers;
        });
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => prevIndex - 1);
    };

    const handleSubmit = () => {
        navigate(ROUTES.survey_result.path, {
            state:
            {
                survey_data: survey,
                survey_answers: answers,
            }
        });
    }

    if (!survey) {
        return (
            <Box>
                <Typography>Something wrong happened, please go back.</Typography>
            </Box>
        )
    }

    const currentQuestion = survey.questions[currentIndex];
    const isFirst = currentIndex === 0;
    const isLast = currentIndex === answers.length - 1;
    const progress = (currentIndex + 1.0) / (answers.length + 1.0) * 100;

    return (
        <StyledContainer>
            <Box sx={{ width: '90%', top: 20 }}>
                <LinearProgress variant="determinate" value={progress} />
                <Box flexGrow={1} paddingTop={2}>
                    <Typography variant="h6">
                        {`Question ${currentIndex + 1}`}
                    </Typography>
                </Box>
            </Box>
            <SurveyQuestion
                question={currentQuestion}
                currentIndex={currentIndex}
                selectedAnswer={answers[currentIndex]}
                onAnswered={handleAnswered}
                onSubmit={handleSubmit}
                onNext={handleNext}
                onPrev={handlePrev}
                isFirst={isFirst}
                isLast={isLast}
            />
        </StyledContainer>
    );
}

export default SurveyQuestionsPage;
