import { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import TopBar from '../navigations/TopBar';
import { useAuth } from '../context/AuthContext';
import SignInComponent from '../components/auth/SignInComponent';
import DrawerNavigationBar from '../navigations/DrawerNavigationBar';
import { PersonaSchema, UserComparisonResponse } from '../utils/interfaces';
import { useParams } from 'react-router-dom';
import { generateUserComparison, searchExactUser } from '../api/friends_api';
import CoreValueChart from '../components/charts/CoreValueChart';
import PersonalityTraitVectorChart from '../components/charts/PersonalityTraitVectorChart';
import CircularProgressWithText from '../components/CircularProgressWithText';
import { GRADIENT_COLORS } from '../const/gradientColors';
import { logEvent } from '../utils/logging';
import ROUTES from '../utils/Routes';
import { ArrowForwardIos } from '@mui/icons-material';

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
    width: '100%',
});

// const DUMMY = {
//     similarities: [
//         "Both alexahn and ipsum AI have high levels of openness to experience.",
//         "alexahn and ipsum AI share a strong sense of empathy towards others.",
//         "Both alexahn and ipsum AI value honesty and integrity.",
//         "alexahn and ipsum AI prefer spending time alone to recharge.",
//         "Both alexahn and ipsum AI prioritize personal growth and self-improvement."
//     ],
//     differences: [
//         "alexahn is more extroverted than ipsum AI.",
//         "ipsum AI is more conscientious than alexahn.",
//         "alexahn values creativity more than ipsum AI.",
//         "ipsum AI is more traditional in their values than alexahn.",
//         "alexahn prefers a more relaxed and flexible lifestyle, while ipsum AI prefers structure and routine."
//     ]
// } as UserComparisonResponse;

function UserComparePage() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { user, isAuthenticated, userInfo, userPersona } = useAuth();
    const { userId } = useParams(); // Get userId from URL parameter
    const [targetUserDisplayName, setTargetUserDisplayName] = useState<string>('');
    const [targetUserPersona, setTargetUserPersona] = useState<PersonaSchema | null>();
    const [comparisonResult, setComparisonResult] = useState<UserComparisonResponse>();
    const [isGeneratingComparison, setIsGeneratingComparison] = useState(false);
    const [error, setError] = useState('');

    // get user and target user persona
    useEffect(() => {
        if (!user || !isAuthenticated() || !userInfo || !userId) return;
        const getUsers = async () => {
            if (user == null || !isAuthenticated() || userInfo == null || userId == null) return;
            const targetUser = await searchExactUser(userId);
            setTargetUserDisplayName(targetUser.displayName);
            setTargetUserPersona(targetUser.persona);
        }
        getUsers();
    }, [user, isAuthenticated, userInfo, userId])

    // get comparison results
    useEffect(() => {
        const getUserComparisons = async () => {
            if (isGeneratingComparison) return;
            if (
                userInfo == null ||
                userPersona == null ||
                targetUserPersona == null ||
                targetUserDisplayName == null ||
                comparisonResult != null
            )
                return;
            try {
                setIsGeneratingComparison(true);
                const userComparisonResult = await generateUserComparison(
                    userInfo.displayName,
                    userPersona,
                    targetUserDisplayName,
                    targetUserPersona
                );
                setError('');
                logEvent({
                    category: 'User Compare',
                    action: 'View User Comparison',
                });
                setComparisonResult(userComparisonResult);
            } catch (error: any) {
                console.error(error);
                setError(error.message);
            } finally {
                setIsGeneratingComparison(false);
            }
        };

        getUserComparisons();
    }, [
        userInfo,
        userPersona,
        targetUserPersona,
        targetUserDisplayName,
        isGeneratingComparison,
        comparisonResult,
    ]);

    // show sign in component if not logged in
    if (!user) {
        return (
            <>
                <TopBar
                    title="Ipsum AI"
                    navigations={
                        [
                            <DrawerNavigationBar />
                        ]
                    } />
                <StyledContainer
                >
                    <SignInComponent />
                </StyledContainer>
            </>
        )
    }

    // return error if viewer userId is invalid
    if (userPersona == null ||
        userPersona.coreValueVector == null ||
        userPersona.personalityTraitVector == null
    ) {
        return (
            <><TopBar
                title="Ipsum AI"
                navigations={
                    [
                        <DrawerNavigationBar />
                    ]
                } />
                <StyledContainer>
                    <Box height="80vh" display="flex" flexDirection="column" justifyContent="center">
                        <Typography maxWidth="300px" variant="caption" p={3}> You must analyze your <b> persona </b> <br /> before comparing yourself with a friend </Typography>
                        <Box>
                            <Button component={Link} to={ROUTES.profile.path} variant="contained" color="primary" sx={{ borderRadius: 3 }}>
                                Go to Profile Page
                                <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
                            </Button>
                        </Box>
                    </Box>
                </StyledContainer>
            </>
        );
    }

    // return error if target userId is invalid
    if (userPersona == null ||
        userPersona.coreValueVector == null ||
        userPersona.personalityTraitVector == null ||
        targetUserPersona == null ||
        targetUserPersona.coreValueVector == null ||
        targetUserPersona.personalityTraitVector == null
    ) {
        return (
            <><TopBar
                title="Ipsum AI"
                navigations={
                    [
                        <DrawerNavigationBar />
                    ]
                } />
                <StyledContainer>
                    <Box height="90vh" display="flex" justifyContent="center" alignItems="center">
                        <Typography p={3} maxWidth="300px">
                            {targetUserDisplayName} has not completed persona analysis
                        </Typography>
                    </Box>
                </StyledContainer>
            </>
        );
    }

    // viewer
    const viewerKeywords = userPersona.coreValueVector.slice(0, 3).map((val) => val.key.toLocaleLowerCase()).concat(userPersona.personalityTraitVector.slice(0, 3).map((val) => val.key.toLocaleLowerCase()));

    // target user

    const keywords = targetUserPersona.coreValueVector.slice(0, 3).map((val) => val.key.toLocaleLowerCase()).concat(targetUserPersona.personalityTraitVector.slice(0, 3).map((val) => val.key.toLocaleLowerCase()));

    const data = [
        {
            name: userInfo?.displayName,
            persona: userPersona,
            vVec: userPersona.coreValueVector,
            pVec: userPersona.personalityTraitVector,
            keywords: viewerKeywords,
        },
        {
            name: targetUserDisplayName,
            persona: targetUserPersona,
            vVec: targetUserPersona.coreValueVector,
            pVec: targetUserPersona.personalityTraitVector,
            keywords: keywords,
        }
    ]

    return (
        <>
            <TopBar
                title="Ipsum AI"
                navigations={
                    [
                        <DrawerNavigationBar />
                    ]
                } />
            <StyledContainer>
                <Box display="flex" flexDirection="row" width="100%">
                    <Grid container spacing={2}>
                        {
                            data.map(({ name, persona, vVec, pVec }, index) => {
                                return (
                                    <Grid key={`grid_index_${index}`} item xs={6} md={6} lg={6}>
                                        <Box key={name} display="flex" justifyContent="center" sx={{ pr: 1, borderRight: index === 0 ? '1px solid #849AAC80' : undefined }}>
                                            <Box flexDirection="column" sx={{ p: 1 }} alignItems="center">
                                                <Box display="flex" flexDirection="column" m={2}>
                                                    <Typography variant={isSmallScreen ? "h6" : "h5"} fontFamily="'Fredericka the Great', cursive;" color="primary.dark" fontWeight={550}>
                                                        {persona.title}
                                                    </Typography>
                                                    <Typography pt={1} variant={isSmallScreen ? "caption" : "subtitle2"} color="primary">
                                                        ({name})
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                    <CoreValueChart coreValueVector={vVec} sort={true} shuffle={false} maxCount={6} width="160px" height="160px" fontSize={6} />
                                                    <PersonalityTraitVectorChart personalityTraitVector={pVec} width="160px" height="160px" />
                                                </Box>
                                                {/* <PersonaKeywords keywords={keywords} /> */}
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
                {
                    isGeneratingComparison && error === '' ? <CircularProgressWithText /> : null
                }
                {
                    comparisonResult != null && !isGeneratingComparison && error === ''
                        ? <Box m={2} display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                            <Box display="flex">
                                <Box m={2} p={2} sx={{ backgroundImage: GRADIENT_COLORS.pastel_similarities, borderRadius: 5, flexBasis: "400px" }} flexGrow={1}>
                                    <Typography p={1} variant="subtitle2" fontWeight={600} color="#05905480">
                                        Similarities
                                    </Typography>
                                    <Box>
                                        {comparisonResult.similarities.map((similarity, index) => (
                                            <Box key={`similarity_${index}`} m={1}>
                                                <Typography variant="caption" fontSize={9}>
                                                    {similarity}
                                                    <br />
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box m={2} p={2} sx={{ backgroundImage: GRADIENT_COLORS.pastel_differences, borderRadius: 5, flexBasis: "400px" }} flexGrow={1}>
                                    <Typography p={1} variant="subtitle2" fontWeight={600} color="#E34E4570">
                                        Differences
                                    </Typography>
                                    <Box>
                                        {comparisonResult.differences.map((difference, index) => (
                                            <Box key={`difference_${index}`} m={1}>
                                                <Typography variant="caption" fontSize={9}>
                                                    {difference}
                                                    <br />
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        : null
                }
                {
                    error !== ''
                        ? <Typography p={3} variant="subtitle2" color="error" >{error}</Typography>
                        : null
                }
            </StyledContainer >
        </>
    );
}

export default UserComparePage;
