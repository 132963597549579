import React from 'react';
import { Box, Typography } from '@mui/material';
import { SurveyResponseType } from '../../utils/interfaces';
import { useSurveyCollectionData } from '../../context/SurveyCollectionDataContext';

interface ProfileSurveyAnswersProps {
    surveyResults: SurveyResponseType[],
    handleShowSurveyContent: (survey: SurveyResponseType) => void,
}

const ProfileSurveyAnswers: React.FC<ProfileSurveyAnswersProps> = ({ surveyResults, handleShowSurveyContent }) => {
    const { surveyCollectionData, loading } = useSurveyCollectionData();
    if (loading || !surveyCollectionData) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="row" flexWrap="wrap" margin={2}>
            {surveyResults.map((surveyResult, index) =>
                <Box
                    key={index}
                    textAlign="center"
                    borderRadius={2}
                    maxWidth="200px"
                    sx={{
                        p: 1,
                        m: 1,
                        textAlign: 'center',
                        position: 'relative',
                        overflow: 'hidden',
                        '&::before': {
                            content: `""`,
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                            backgroundImage: surveyCollectionData ? `url(${surveyCollectionData.find((survey) => survey.key === surveyResult.key)?.backgroundTileImageSrc})` : '',
                            backgroundRepeat: 'repeat',
                            backgroundSize: '100px 100px',
                            opacity: 0.3,
                            zIndex: -1,
                            overflow: 'auto',
                        },
                        '&:hover::before': {
                            opacity: 0.5,
                        },
                    }}
                >
                    <Box sx={{ position: 'relative', zIndex: 1 }} onClick={() => handleShowSurveyContent(surveyResult)}>
                        <Typography
                            variant="caption"
                            color="secondary.dark"
                            sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'auto',
                            }}
                        >
                            <b>{surveyResult.title}</b>
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default ProfileSurveyAnswers