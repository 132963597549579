import { useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { styled } from '@mui/system';
import TopBar from '../navigations/TopBar';
import { useAuth } from '../context/AuthContext';
import SignInComponent from '../components/auth/SignInComponent';
import DrawerNavigationBar from '../navigations/DrawerNavigationBar';
import UserInfoForm from '../components/auth/UserInfoForm';
import FriendAddComponent from '../components/friends/FriendAddComponent';
import FriendsListComponent from '../components/friends/FriendsListComponent';
import FriendRequestsComponent from '../components/friends/FriendsRequestsComponent';
import { Link } from 'react-router-dom';
import { UserFriend } from '../utils/interfaces';
import { getFriends, getPendingFriendRequests } from '../api/friends_api';
import { ArrowForwardIos, Person } from '@mui/icons-material';
import ROUTES from '../utils/Routes';
import CircularProgressBase from '../components/CircularProgressBase';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
});

function FriendsPage() {
    const { t } = useTranslation();
    const { user, isAuthenticated, userInfo, userPersona } = useAuth();
    const [isValidUser, setIsValidUser] = useState(true);
    const [hasUserInfo, setHasUserInfo] = useState(true);
    const [friends, setFriends] = useState<UserFriend[]>([]);
    const [pendingFriendsIDs, setPendingFriendsIDs] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // authenticate user
    useEffect(() => {
        setIsValidUser(isAuthenticated());
        setHasUserInfo(userInfo !== null);
    }, [user, isAuthenticated, userInfo])

    // fetch friends
    useEffect(() => {
        const fetchFriends = async () => {
            if (!user) return;
            setIsLoading(true);
            const [
                friends,
                friendRequests
            ] = await Promise.all([
                getFriends(user.uid),
                getPendingFriendRequests(user.uid)
            ]);
            setFriends(friends);
            setPendingFriendsIDs(friendRequests.map((request) => request.receiverId));
            setIsLoading(false);
        };
        // fetch
        fetchFriends();
    }, [user]);

    // show sign in component if not logged in
    if (!isValidUser || !user) {
        return (
            <>
                <TopBar
                    title="Ipsum AI"
                    navigations={
                        [
                            <DrawerNavigationBar />
                        ]
                    } />
                <StyledContainer
                >
                    <SignInComponent />
                </StyledContainer>
            </>
        )
    }

    // show user info input if missing user info
    if (!hasUserInfo || userInfo == null) {
        return (
            <>
                <TopBar
                    title="Ipsum AI"
                    navigations={
                        [
                            <DrawerNavigationBar />
                        ]
                    } />
                <StyledContainer>
                    <UserInfoForm userId={user.uid} description={t('UserForm_FriendsDescription')} />
                </StyledContainer>
            </>
        )
    }

    // ask to get persona first
    if (userPersona == null) {
        return (
            <>
                <TopBar
                    title="Ipsum AI"
                    navigations={
                        [
                            <DrawerNavigationBar />
                        ]
                    } />
                <StyledContainer>
                    <Box height="80vh" display="flex" flexDirection="column" justifyContent="center">
                        <Typography maxWidth="300px" variant="caption" p={3}> {t('FriendsPage_CompleteProfile')} </Typography>
                        <Box>
                            <Button component={Link} to={ROUTES.profile.path} variant="contained" color="primary" sx={{ borderRadius: 3 }} startIcon={<Person />}>
                                {t('FriendsPage_GoToProfilePage')}
                                <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
                            </Button>
                        </Box>
                    </Box>
                </StyledContainer>
            </>
        )
    }

    // loading
    if (isLoading) {
        return (
            <>
                <TopBar
                    title="Ipsum AI"
                    navigations={
                        [
                            <DrawerNavigationBar />
                        ]
                    } />
                <StyledContainer>
                    <CircularProgressBase isFullscreen title="loading your friends..." />
                </StyledContainer>
            </>
        )
    }

    return (
        <>
            <TopBar
                title="Ipsum AI"
                navigations={
                    [
                        <DrawerNavigationBar />
                    ]
                } />
            <StyledContainer
            >
                <FriendAddComponent user={user} userInfo={userInfo} friends={friends} pendingFriendsIDs={pendingFriendsIDs} setPendingFriendsIDs={setPendingFriendsIDs} />
                <FriendRequestsComponent user={user} userInfo={userInfo} friends={friends} setFriends={setFriends} />
                <Divider sx={{ width: '90%' }} />
                <FriendsListComponent user={user} userInfo={userInfo} friends={friends} />
            </StyledContainer>
        </>
    );
}

export default FriendsPage;
