import { SurveyQuestionFormType, SurveyResultDataType } from "./interfaces";

// convert to SurveyQuestionFormType[] and return JSON string
export function convertSurveyAnswersToText(props: SurveyResultDataType): string {
    return JSON.stringify(
        props.survey_data.questions.map((survey_question, index) => {
            return {
                question: survey_question.question,
                answer: survey_question.options[props.survey_answers[index]]
            } as SurveyQuestionFormType
        })
    );
}

// convert array of JSON string of SurveyQuestionFormType[] to single concat'ed SurveyQuestionFormType[] string
export function parseSurveyDataJSONToString(jsonData: string[]): string {
    let combinedResults: SurveyQuestionFormType[] = [];
    jsonData.forEach(data => {
        try {
            const parsedData: SurveyQuestionFormType[] = JSON.parse(data);
            combinedResults = combinedResults.concat([...parsedData]);
        } catch (error) {
            console.error('Error parsing JSON data:', error);
        }
    });
    return combinedResults.map((result) => {
        return `question:${result.question}\nanswer:${result.answer}\n`
    }).join('\n')
}
