import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SurveyResultDataType, SurveyEvaluationType, EvaluationVector } from '../utils/interfaces';
import SurveyResultHeader from '../components/survey/SurveyResultHeader';
import SurveyResultContent from '../components/survey/SurveyResultContent';
import SurveyResultFooter from '../components/survey/SurveyResultFooter';
import ROUTES from '../utils/Routes';
import { useAuth } from '../context/AuthContext';
import { generateSurveyEvaluationVector, generateSurveyEvaluation } from '../api/api';
import SignInModal from '../components/auth/SignInModal';
import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import { saveSurveyResults } from '../api/firestore';
import { convertSurveyAnswersToText } from '../utils/utils';
import { logEvent } from '../utils/logging';

const DUMMY = {
    title: '',
    subtitle: '',
    reason: '',
} as SurveyEvaluationType;

// let { survey_data, survey_answers } = location.state as SurveyResultDataType;
// if (survey_data.key === 'fictional_character') {
//     // character
//     survey_answers = ['a', 'f', 'e', 'd', 'f', 'f', 'd', 'a', 'b', 'c', 'c', 'g', 'a', 'b', 'd'];
// } else if (survey_data.key === 'iconic_quote') {
//     // quote
//     survey_answers = ['c', 'b', 'b', 'b', 'f', 'd', 'e', 'b', 'b', 'b', 'a', 'c', 'c', 'a'];
// } else if (survey_data.key === 'favorite_song') {
//     // song
//     survey_answers = ['f', 'e', 'b', 'f', 'a', 'b', 'a', 'd', 'c', 'c', 'a', 'd', 'd', 'e', 'd'];
// }

function SurveyResultPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, isAuthenticated, isGuest, setIsGuest } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [openSignInModal, setOpenSignInModal] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const { survey_data, survey_answers } = location.state as SurveyResultDataType;
    const [surveyEvaluationResult, setSurveyEvaluationResult] = useState<SurveyEvaluationType>(DUMMY);
    const [surveyEvaluationVectorResult, setSurveyEvaluationVectorResult] = useState<EvaluationVector>([]);
    const answerGenerated = surveyEvaluationResult?.title !== '' && surveyEvaluationVectorResult.length > 0;
    const hasError = error !== '';

    const [shouldSignIn, setShouldSignIn] = useState(false);
    useEffect(() => {
        if (isGuest) {
            setShouldSignIn(false);
            return;
        }
        if (user && isAuthenticated()) {
            setShouldSignIn(false);
            return;
        }
        setShouldSignIn(true);
    }, [user, isAuthenticated, isGuest])

    useEffect(() => {
        if (!isLoading || !survey_data || !survey_answers || answerGenerated) return;
        const generate = async () => {
            try {
                const user_input_data = convertSurveyAnswersToText({ survey_data, survey_answers });
                const [
                    surveyEvaluation,
                    surveyEvaluationVector,
                ] = await Promise.all([
                    generateSurveyEvaluation(survey_data, user_input_data),
                    generateSurveyEvaluationVector(survey_data, user_input_data),
                ]);
                setSurveyEvaluationResult(surveyEvaluation);
                setSurveyEvaluationVectorResult(surveyEvaluationVector);
            } catch (error: any) {
                setError(error.message);
                logEvent({
                    category: "Survey",
                    action: "Generate Survey Result Fail",
                    label: survey_data.key,
                })
            } finally {
                setIsLoading(false);
            }
        };
        generate();
    }, [isLoading, location, survey_answers, survey_data, user, answerGenerated]);

    useEffect(() => {
        const save = async () => {
            if (!user || !survey_data || !survey_answers || !answerGenerated) return;
            await saveSurveyResults(user.uid, survey_data.key, surveyEvaluationResult, surveyEvaluationVectorResult, convertSurveyAnswersToText({ survey_data, survey_answers }));
            logEvent({
                category: "Survey",
                action: "Save Survey Result",
                label: survey_data.key,
            })
        }
        save();
    }, [user, survey_data, survey_answers, answerGenerated, surveyEvaluationResult, surveyEvaluationVectorResult])

    const onSubmit = () => {
        setError('');
        setIsLoading(true);
        logEvent({
            category: "Survey",
            action: "Click Get Survey Result",
            label: survey_data.key,
        })
    }

    const onExit = async () => {
        logEvent({
            category: "Survey",
            action: "Click Exit Survey",
            label: survey_data.key,
        })
        setSurveyEvaluationResult(DUMMY);
        setError('');
        navigate(ROUTES.main.path);
    }

    if (shouldSignIn) {
        return (
            <Box display="flex" flexDirection="column" height="90vh" alignItems="center" justifyContent="center">
                <Typography variant='h5' color="primary" sx={{ textAlign: 'center', p: 2 }}>
                    {survey_data.title}
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                    <Box
                        borderRadius={8}
                        height="150px"
                        width="150px"
                        sx={{
                            backgroundImage: `url(${survey_data.backgroundImageSrc})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}
                    />
                </Box>
                <Typography variant='subtitle2' color="primary" sx={{ textAlign: 'center', m: 3, maxWidth: '230px' }}>
                    sign in to save and compare your results with your friends
                </Typography>
                <Box display="flex" flexDirection="column" mt={2}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setOpenSignInModal(true);
                            logEvent({
                                category: "Survey",
                                action: "Click Sign In",
                                label: survey_data.key,
                            });
                        }}
                        sx={{ borderRadius: 8, m: 1 }}>
                        Sign In
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            setIsGuest(true);
                            logEvent({
                                category: "Survey",
                                action: "Click Continue As Guest",
                                label: survey_data.key,
                            });
                        }}
                        sx={{ borderRadius: 8, m: 1, border: 'hidden' }}>
                        Continue as Guest
                    </Button>
                </Box>
                <SignInModal open={openSignInModal} setOpen={setOpenSignInModal} />
            </Box>
        )
    }

    return (
        <Box display='flex' justifyContent="center">
            <Box
                display="flex"
                flexDirection="column"
            >
                <SurveyResultHeader
                    title={survey_data.title}
                    iconImageSrc={survey_data.backgroundImageSrc}
                    answerGenerated={answerGenerated}
                    hasError={hasError}
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                />
                <SurveyResultContent
                    error={error}
                    isLoading={isLoading}
                    surveyEvaluation={surveyEvaluationResult}
                    surveyEvaluationVector={surveyEvaluationVectorResult}
                />
                <SurveyResultFooter
                    answerGenerated={answerGenerated}
                    isLoading={isLoading}
                    hasError={hasError}
                    onExit={onExit}
                    onSubmit={onSubmit}
                    openSignInModal={() => setOpenSignInModal(true)}
                />
                <Snackbar
                    open={openToast}
                    autoHideDuration={6000}
                    onClose={() => setOpenToast(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert onClose={() => {
                        setOpenToast(false);
                    }} severity="success" sx={{ width: '100%', m: 3 }}>
                        successfully saved to profile
                    </Alert>
                </Snackbar>
                <SignInModal open={openSignInModal} setOpen={setOpenSignInModal} />
            </Box>
        </Box>
    );
}

export default SurveyResultPage;
