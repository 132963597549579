export const sendPageViewEventFB = async () => {
    try {
        const ipresponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipresponse.json();
        const ipAddress = ipData.ip;
        const userAgent = navigator.userAgent;
        const fbp = document.cookie.match('(^|;)\\s*fbp\\s*=\\s*([^;]+)')?.pop() || '';
        const response = await fetch(`https://graph.facebook.com/v16.0/2618962298380840/events?access_token=${process.env.REACT_APP_FB_TOKEN}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "data": [
                    {
                        "event_name": "PageView",
                        "event_time": Math.floor(new Date().getTime() / 1000),
                        "action_source": "website",
                        "user_data": {
                            client_ip_address: ipAddress,
                            client_user_agent: userAgent,
                            fbp: fbp,
                        },
                        "custom_data": {}
                    }
                ]
            }),
        });
        const data = await response.json();
        if (response.status !== 200) {
            throw data.error || new Error(`Request failed with status ${response.status}`);
        }
        return data;
    } catch (error: any) {
        console.error(error);
        throw new Error(`Something wrong happened while generating. Please try again.`);
    }
};