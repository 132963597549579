import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
// import FacebookIcon from '@mui/icons-material/Facebook';
// import { Google } from "@mui/icons-material";
import { useState } from "react";
import SignUpComponent from "./SignUpComponent";
import { logEvent } from "../../utils/logging";

interface SignInProps {
    callback?: () => void;
}

const SignInComponent: React.FC<SignInProps> = ({ callback }) => {
    const { signInWithEmailAndPassword } = useAuth();
    const [showSignUp, setShowSignUp] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    // const [isInAppBrowser, setIsInAppBrowser] = useState(false);

    // useEffect(() => {
    //     const isInApp = window.matchMedia('(display-mode: standalone)').matches;
    //     setIsInAppBrowser(isInApp);
    // }, []);

    if (showSignUp) {
        return <SignUpComponent callback={() => {
            setShowSignUp(false);
            if (callback) {
                callback();
            }
        }} />;
    }

    const handleEmailSignIn = async () => {
        try {
            await signInWithEmailAndPassword(email, password);
            logEvent({
                category: "Authentication",
                action: "Sign In",
                label: 'email'
            })
            if (callback) callback();
        } catch (error: any) {
            console.error("Error signing in with email:", error);
            setError(error.message.toString());
            logEvent({
                category: "Authentication",
                action: "Sign In Fail",
                label: 'email'
            })
        }
    };

    // const handleGoogleSignIn = async () => {
    //     try {
    //         await signInWithGoogle();
    //         logEvent({
    //             category: "Authentication",
    //             action: "Sign In",
    //             label: 'google'
    //         })
    //         if (callback) callback();
    //     } catch (error) {
    //         console.error('Error signing in with Google:', error);
    //         logEvent({
    //             category: "Authentication",
    //             action: "Sign In Fail",
    //             label: 'google'
    //         })
    //     }
    // };

    // const handleFacebookSignIn = async () => {
    //     try {
    //         await signInWithFacebook();
    //         logEvent({
    //             category: "Authentication",
    //             action: "Sign In",
    //             label: 'facebook'
    //         })
    //         if (callback) callback();
    //     } catch (error) {
    //         console.error('Error signing in with Facebook:', error);
    //         logEvent({
    //             category: "Authentication",
    //             action: "Sign In Fail",
    //             label: 'facebook'
    //         });
    //     }
    // };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ pt: 7, pb: 7 }} width="300px">
            <Box p={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h6">
                    Sign in to see more
                </Typography>
                <Typography variant="caption">
                    Discover Your True Self
                </Typography>
            </Box>
            <Box width="200px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    required
                    fullWidth
                    margin="dense"
                />
                <TextField
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    required
                    fullWidth
                    margin="dense"
                />
                <Button variant="contained" onClick={handleEmailSignIn} sx={{ m: 1, alignItems: "center" }} fullWidth>
                    Continue
                </Button>
                {error && (
                    <Typography variant="caption" color="error">
                        {error}
                    </Typography>
                )}
                {/* {!isInAppBrowser ? <>
                    <Typography variant="caption" p={1}> <b>OR</b> </Typography>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Button variant="outlined" startIcon={<Google />} onClick={handleGoogleSignIn} sx={{ m: 1, fontSize: '9px' }} fullWidth>
                            Continue with Google
                        </Button>
                    </Box>
                    <Button variant="outlined" startIcon={<FacebookIcon />} onClick={handleFacebookSignIn} sx={{ m: 1, alignItems: 'center', fontSize: '9px' }} fullWidth >
                        Continue with Facebook
                    </Button>
                </> : null} */}
            </Box>
            <Divider variant="fullWidth" sx={{ width: '250px', m: 1 }} />
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Typography variant="body2" sx={{ m: 1 }}>
                    Don't have an account?{" "}
                </Typography>
                <Typography
                    variant="caption"
                    onClick={() => setShowSignUp(true)}
                    sx={{ textDecoration: 'underline', cursor: 'pointer', color: 'primary.main' }}
                >
                    <b>Sign Up</b>
                </Typography>
            </Box>
        </Box>
    )
}

export default SignInComponent;
