import React, { useState } from 'react';
import { saveUserInfo } from '../../api/firestore';
import { Box, Button, TextField, Typography, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { UserInfo } from '../../utils/interfaces';
import { useTranslation } from 'react-i18next';

interface UserInfoFormProps {
    userId: string;
    description: string,
}

const UserInfoForm: React.FC<UserInfoFormProps> = ({ userId, description }) => {
    const { t } = useTranslation();
    const { setUserInfoManually } = useAuth();
    const [displayName, setUserName] = useState('');
    const [birthday, setBirthday] = useState('');
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const timestamp = new Date().toString();
        const userInfo = {
            userId,
            displayName,
            created: timestamp,
            lastActive: timestamp,
            birthday,
            gender,
            country,
        } as UserInfo;
        await saveUserInfo(userInfo)
        setUserInfoManually(userInfo);
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ pt: 7, pb: 7 }} width="300px">
            <Typography variant="h6" p={2}>
                {t('UserForm_Title')}<br />{description}
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box width="230px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <TextField
                        label="User Name"
                        id="displayName"
                        value={displayName}
                        onChange={(e) => setUserName(e.target.value)}
                        required
                        fullWidth
                        margin="dense"
                        placeholder="Alex"
                    />

                    <FormControl fullWidth margin="dense">
                        <TextField
                            label="Birthday"
                            id="birthday"
                            type="date"
                            value={birthday}
                            onChange={(e) => setBirthday(e.target.value)}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>

                    <FormControl fullWidth margin="dense">
                        <InputLabel htmlFor="gender">Gender</InputLabel>
                        <Select
                            label="Gender"
                            id="gender"
                            value={gender}
                            onChange={(e) => setGender(e.target.value as string)}
                            fullWidth
                        >
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="dense">
                        <InputLabel htmlFor="country">Country</InputLabel>
                        <Select
                            label="Country"
                            id="country"
                            value={country}
                            onChange={(e) => setCountry(e.target.value as string)}
                            fullWidth
                        >
                            {/* Add MenuItem components for each country */}
                            <MenuItem value="United States">United States</MenuItem>
                            <MenuItem value="Canada">Canada</MenuItem>
                            <MenuItem value="Mexico">Mexico</MenuItem>
                            <MenuItem value="France">France</MenuItem>
                            <MenuItem value="England">England</MenuItem>
                            <MenuItem value="South Korea">South Korea</MenuItem>
                            {/* ... */}
                        </Select>
                    </FormControl>

                    <Button
                        variant="contained"
                        type="submit"
                        sx={{ m: 1, alignItems: "center" }}
                        fullWidth
                        disabled={!displayName}
                    >
                        Save Information
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default UserInfoForm;
