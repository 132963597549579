import React from 'react';
import { Box, Typography } from '@mui/material';
import HashtagComponent from '../charts/HashtagComponent';

interface PersonaComponentProps {
    keywords: string[],
}

const PersonaKeywords: React.FC<PersonaComponentProps> = ({ keywords }) => {
    return (
        <>
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center" textAlign="justify" maxWidth="500px" mb={3}>
                {keywords.map((keyword) => <Typography key={keyword} variant="caption" fontSize="9px" height="30px" style={{ whiteSpace: "pre-wrap" }}> <HashtagComponent hashtag={keyword} /> </Typography>)}
            </Box>
        </>
    )
}

export default PersonaKeywords;
