import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../utils/firebaseConfig";
import { UserFriendConnection, UserFriendRequest, UserFriend, UserInfoSearchResult, PersonaSchema, UserPageData, UserComparisonResponse, SurveyResponseType } from "../utils/interfaces";
import { v4 as uuidv4 } from 'uuid';

export const searchUsers = async (searcherUID: string, searchText: string): Promise<UserInfoSearchResult[]> => {
    try {
        const querySnapshot = await getDocs(collection(db, 'users'));
        const promises = querySnapshot.docs
            .filter((document) => document.id !== searcherUID)
            .map(async (document) => {
                const data = document.data();
                const personaRef = doc(db, 'persona', document.id);
                const personaDoc = await getDoc(personaRef);
                const personaData = personaDoc.data();
                const displayName = data.displayName?.toLowerCase() ?? data.userName?.toLowerCase() ?? '';
                if (data.uid === searchText || displayName.includes(searchText.toLowerCase())) {
                    return {
                        userId: document.id,
                        displayName: data.displayName ?? data.userName ?? '',
                        title: personaData?.title,
                    };
                }
                return null;
            });
        const results = await Promise.all(promises);
        return results.filter((result) => result !== null).slice(0, 10) as UserInfoSearchResult[];
    } catch (error) {
        console.error('Error searching users: ', error);
        throw error;
    }
};

export const searchExactUser = async (userId: string): Promise<UserPageData> => {
    try {
        const [
            userRef,
            userPersonaRef,
            userSurveyResultRef
        ] = await Promise.all([
            getDoc(doc(db, 'users', userId)),
            getDoc(doc(db, 'persona', userId)),
            getDocs(collection(db, "surveys", userId, "results")),
        ])
        const user = userRef.exists() ? userRef.data() : null;
        const userPersona = userPersonaRef.exists() ? userPersonaRef.data() as PersonaSchema : null;
        const userSurveyResults = userSurveyResultRef.docs.map((surveyResultDoc) => surveyResultDoc.data() as SurveyResponseType)
        return {
            displayName: user?.displayName,
            persona: userPersona,
            completedSurveys: userSurveyResults,
        };
    } catch (error) {
        console.error('Error searching users: ', error);
        throw error;
    }
};

export const sendFriendRequest = async (senderId: string, senderName: string, receiverId: string, receiverName: string): Promise<void> => {
    try {
        const uuid = uuidv4();
        const friendRequestDocRef = doc(collection(db, 'friend_requests'), uuid);
        const friendRequest: UserFriendRequest = {
            requestId: uuid,
            senderId,
            senderName,
            receiverId,
            receiverName,
            status: 'pending',
            timestamp: new Date().toString(),
        };
        await setDoc(friendRequestDocRef, friendRequest);
        console.log('Friend request sent successfully.');
    } catch (error) {
        console.error('Error sending friend request: ', error);
        throw error;
    }
};

export const getPendingFriendRequests = async (senderId: string): Promise<UserFriendRequest[]> => {
    try {
        const senderFriendRequestRef = collection(db, 'friend_requests');
        const query1 = query(senderFriendRequestRef, where('senderId', '==', senderId), where('status', '==', 'pending'));
        const snapshot = await getDocs(query1);
        const friendRequests: UserFriendRequest[] = [];
        snapshot.forEach((doc) => {
            const data = doc.data();
            friendRequests.push(data as UserFriendRequest);
        })
        return friendRequests;
    } catch (error) {
        console.error("Error retrieving friend requests");
        return [];
    }
}

export const acceptFriendRequest = async (friendRequestId: string): Promise<void> => {
    const friendRequestRef = doc(collection(db, 'friend_requests'), friendRequestId);

    try {
        const friendRequestSnapshot = await getDoc(friendRequestRef);
        const friendRequestData = friendRequestSnapshot.data() as UserFriendRequest;

        if (friendRequestData.status !== 'pending') {
            console.error('Friend request is not pending.');
            return;
        }

        const { senderId, senderName, receiverId, receiverName } = friendRequestData;

        // Update friend request status
        await updateDoc(friendRequestRef, { status: 'accepted' });

        // Add new friend connection document
        const userFriendsRef = collection(db, 'user_friends');
        const senderIsUser1 = senderId < receiverId;
        const since = new Date().toISOString();
        let result = {
            connectionId: `${receiverId}_${senderId}`,
            user1Id: receiverId,
            user1Name: receiverName,
            user2Id: senderId,
            user2Name: senderName,
            since,
        }
        if (senderIsUser1) {
            result = {
                connectionId: `${senderId}_${receiverId}`,
                user1Id: senderId,
                user1Name: senderName,
                user2Id: receiverId,
                user2Name: receiverName,
                since,
            }
        }
        await setDoc(doc(userFriendsRef, result.connectionId), result);
    } catch (error) {
        console.error('Error accepting friend request:', error);
        throw error;
    }
};


export const declineFriendRequest = async (requestId: string): Promise<void> => {
    try {
        cancelFriendRequest(requestId);
    } catch (error) {
        console.error('Error declining friend request: ', error);
        throw error;
    }
};

export const cancelFriendRequest = async (requestId: string): Promise<void> => {
    try {
        const friendRequestRef = doc(collection(db, 'friend_requests'), requestId);
        await deleteDoc(friendRequestRef);
    } catch (error) {
        console.error('Error cancelling friend request: ', error);
        throw error;
    }
};

export const getFriends = async (userId: string): Promise<UserFriend[]> => {
    try {
        const friendsRef = collection(db, 'user_friends');
        const query1 = query(friendsRef, where('user1Id', '==', userId));
        const query2 = query(friendsRef, where('user2Id', '==', userId));
        const [querySnapshot1, querySnapshot2] = await Promise.all([getDocs(query1), getDocs(query2)]);

        const friends: UserFriend[] = [];

        for (const document of querySnapshot1.docs) {
            const data = document.data();
            const personaRef = doc(db, 'persona', data.user2Id);
            const personaDoc = await getDoc(personaRef);
            const personaData = personaDoc.data();
            friends.push({
                userId: data.user2Id,
                displayName: data.user2Name,
                since: data.since,
                connectionId: data.connectionId,
                title: personaData?.title,
            });
        }

        for (const document of querySnapshot2.docs) {
            const data = document.data();
            const personaRef = doc(db, 'persona', data.user1Id);
            const personaDoc = await getDoc(personaRef);
            const personaData = personaDoc.data();
            friends.push({
                userId: data.user1Id,
                displayName: data.user1Name,
                since: data.since,
                connectionId: data.connectionId,
                title: personaData?.title,
            });
        }

        return friends;
    } catch (error) {
        console.error('Error getting friends: ', error);
        throw error;
    }
};

export const generateUserComparison = async (userDisplayName: string, userPersona: PersonaSchema, targetUserDisplayName: string, targetUserPersona: PersonaSchema): Promise<UserComparisonResponse> => {
    try {
        // console.log({
        //     my_name: userDisplayName,
        //     my_persona: userPersona,
        //     friend_name: targetUserDisplayName,
        //     friend_persona: targetUserPersona,
        // });
        const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/generateUserComparison`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: {
                    my_name: userDisplayName,
                    my_persona: userPersona,
                    friend_name: targetUserDisplayName,
                    friend_persona: targetUserPersona,
                },
            }),
        });
        const responseData = await response.json();
        // console.log('responseData', responseData);
        if (response.status !== 200) {
            throw responseData.error || new Error(`Request failed with status ${response.status}`);
        }
        // console.log('generateUserComparison: ', responseData.result);
        const result = JSON.parse(responseData.result) as UserComparisonResponse;
        return result;
    } catch (error) {
        console.error('Error comparing users: ', error);
        throw error;
    }
};

export const removeFriend = async (user1Id: string, user2Id: string): Promise<void> => {
    // TODO: implement
};

export const getFriendRequests = async (userId: string): Promise<UserFriendRequest[]> => {
    const friendRequestsRef = collection(db, "friend_requests");
    const q = query(friendRequestsRef, where("receiverId", "==", userId), where("status", "==", "pending"));
    const querySnapshot = await getDocs(q);
    const friendRequests: UserFriendRequest[] = [];
    querySnapshot.forEach((doc) => {
        friendRequests.push({
            requestId: doc.id,
            senderId: doc.data().senderId,
            senderName: doc.data().senderName,
            receiverId: doc.data().receiverId,
            receiverName: doc.data().receiverName,
            status: doc.data().status,
            timestamp: doc.data().timestamp,
        });
    });
    return friendRequests;
};

export const getFriendConnections = async (userId: string): Promise<UserFriend[]> => {
    // Retrieves all friend connections for the given user
    return [];
};

export const checkFriendship = async (user1Id: string, user2Id: string): Promise<boolean> => {
    // Checks if two users are friends (i.e., have an established connection)
    return false;
};

export const getFriendConnection = async (user1Id: string, user2Id: string): Promise<UserFriendConnection | null> => {
    // Retrieves the friend connection between two users, if it exists
    return null;
};
