import React from 'react';
import { Box, Typography } from "@mui/material"

type HashtagComponentProps = {
    hashtag: string,
    opacity?: number,
    backgroundColor?: string,
    emoji?: string,
}

const HashtagComponent: React.FC<HashtagComponentProps> = ({ hashtag, opacity, backgroundColor, emoji }: HashtagComponentProps) => {
    if (emoji) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ borderRadius: 2, opacity, backgroundColor: backgroundColor ?? 'rgba(0,0,0,0.1)', padding: '3px', margin: '2px' }}>
                <Typography variant="caption" fontSize='10px' pr={0.5}>
                    {emoji}
                </Typography>
                <Typography variant="caption" fontSize='7px'>
                    {hashtag}
                </Typography>
            </Box>
        )
    }
    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ borderRadius: 2, opacity, backgroundColor: backgroundColor ?? 'rgba(0,0,0,0.1)', padding: '3px', margin: '2px' }}>
            <Typography variant="caption" fontSize='7px'>
                {hashtag}
            </Typography>
        </Box>
    )

}

export default HashtagComponent