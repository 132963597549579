import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ROUTES from './utils/Routes';
import MainPage from './pages/MainPage';
import Box from '@mui/material/Box';
import SurveyQuestionsPage from './pages/SurveyQuestionsPage';
import SurveyResultPage from './pages/SurveyResultPage';
import SurveyEntryPage from './pages/SurveyEntryPage';
import { CssBaseline } from '@mui/material';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import ProfilePage from './pages/ProfilePage';
import AuthProvider from './provider/AuthProvider';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import { sendPageViewEventFB } from './api/facebookAPI';
import { useTranslation } from 'react-i18next';
import { SurveyCollectionDataProvider } from './context/SurveyCollectionDataContext';
import FriendsPage from './pages/FriendsPage';
import UserPage from './pages/UserPage';
import UserComparePage from './pages/UserComparePage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import ColorsPage from './pages/ColorsPage';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? '');
sendPageViewEventFB();

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontFamily: "Comfortaa, Outfit, Robotto, sans-serif"
        },
        body: {
          margin: "0",
          fontFamily: "Comfortaa, Outfit, Robotto, sans-serif",
        }
      },
    },
  },
  typography: {
    fontFamily: [
      'Comfortaa',
      'Outfit',
      'Roboto',
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#6D799C', // dusty blue
      light: '#8b95b1', // lighter dusty blue
      dark: '#496179', // dark dusty blue
      contrastText: '#fff',
    },
    secondary: {
      main: '#7BB8B1', // dusty teal
      light: '#587B77', // lighter dusty teal
      dark: '#396A75', // dark teal
      contrastText: '#fff',
    },
    error: {
      main: '#FF6781', // coral
      light: '#ffe4e4', // coral (lighter shade)
      dark: '#ffdfdf', // crimson
      contrastText: '#333',
    },
    warning: {
      main: '#fdecb7', // mustard
      light: '#fff9e6', // mustard (lighter shade)
      dark: '#fff6e5', // burnt sienna
      contrastText: '#333',
    },
    info: {
      main: '#9F978D', // gold grey
      light: '#f7f6ff', // light purple (lighter shade)
      dark: '#f2f1ff', // deep purple
      contrastText: '#333',
    },
    success: {
      main: '#8ED8BC', // mint
      light: '#D8EAE4', // mint (lighter shade)
      dark: '#e2fbeb', // teal
      contrastText: '#333',
    },
  },
});

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <SurveyCollectionDataProvider>
          <Router>
            <Box component="main">
              <Box
                display="flex"
                flexDirection="column"
              >
                <Routes>
                  {/* main */}
                  <Route path={ROUTES.main.path} element={<MainPage />} />
                  {/* survey */}
                  <Route path={ROUTES.survey.path} element={<Outlet />}>
                    <Route path=":type" element={<Outlet />}>
                      <Route index element={<SurveyEntryPage />} />
                      <Route path={ROUTES.survey_questions.path} element={<Outlet />} >
                        <Route index element={<SurveyQuestionsPage />} />
                        <Route path={ROUTES.survey_result.path} element={<SurveyResultPage />} />
                      </Route>
                      <Route path={'*'} element={<div>{t("PageNotFound")}</div>} />
                    </Route>
                    <Route path={'*'} element={<div>{t("SurveyNotFound")}</div>} />
                  </Route>
                  {/* Profile */}
                  <Route path={ROUTES.profile.path} element={<ProfilePage />} />
                  {/* Friends */}
                  <Route path={ROUTES.friends.path} element={<FriendsPage />} />
                  {/* User */}
                  <Route path={ROUTES.user.path} element={<Outlet />}>
                    <Route path=":userId" element={<Outlet />}>
                      <Route index element={<UserPage />} />
                    </Route>
                    <Route path={'*'} element={<div>{t("UserNotFound")}</div>} />
                  </Route>
                  {/* Compare User */}
                  <Route path={ROUTES.compare_user.path} element={<Outlet />}>
                    <Route path=":userId" element={<Outlet />}>
                      <Route index element={<UserComparePage />} />
                    </Route>
                    <Route path={'*'} element={<div>{t("UserNotFound")}</div>} />
                  </Route>
                  {/* colors page */}
                  <Route path={ROUTES.colors.path} element={<ColorsPage />} />
                  {/* privacy policy */}
                  <Route path={ROUTES.privacy.path} element={<PrivacyPolicyPage />} />
                  {/* terms and conditions */}
                  <Route path={ROUTES.terms.path} element={<TermsAndConditionsPage />} />
                  {/* 404 */}
                  <Route path={'*'} element={<div>{t("PageNotFound")}</div>} />
                </Routes>
              </Box>
            </Box>
          </Router>
        </SurveyCollectionDataProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;