import React from 'react';
import { Box, Typography } from '@mui/material';
import { PersonaSchema } from '../../utils/interfaces';
import { GRADIENT_COLORS } from '../../const/gradientColors';

interface PersonaComponentProps {
    personaReport: PersonaSchema,
}

const PersonaDescription: React.FC<PersonaComponentProps> = ({ personaReport }) => {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box
                mt={1}
                p={3}
                pt={2}
                pb={2}
                borderRadius={7}
                width="95%"
                maxWidth="500px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="justify"
                flexWrap="wrap"
                sx={{ backgroundImage: GRADIENT_COLORS.pastel_main }}
            >
                <Typography variant="caption" sx={{ fontSize: 10 }}>
                    {personaReport.description}
                </Typography>
            </Box>
            <Box
                m={2}
                p={2}
                borderRadius={7}
                width="95%"
                maxWidth="500px"
                sx={{ backgroundImage: GRADIENT_COLORS.pastel_secondary }}>
                {
                    personaReport.quotes.map((quote, index) => {
                        return (
                            <Box key={`quote_${index}`}>
                                <Typography variant="caption" sx={{ fontSize: 9, fontWeight: 500 }}>
                                    "{quote}"
                                </Typography>
                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export default PersonaDescription;
