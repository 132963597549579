import React from "react";
import type { EChartsOption } from "echarts";
import { Avatar, Box, useTheme } from "@mui/material";
import ReactECharts from "./ReactECharts";
import { EvaluationVector } from "../../utils/interfaces";
import { getCoreValueColor } from "../../utils/chartUtils";

function shuffleArray(arr: EvaluationVector) {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
}

interface ProfileChartProps {
    coreValueVector: EvaluationVector,
    shuffle: boolean;
    sort: boolean;
    maxCount: number;
    width: string;
    height: string;
    fontSize: number;
    centerImageURI?: string;
}

const CoreValueChart: React.FC<ProfileChartProps> = ({ coreValueVector, sort, shuffle, maxCount, width, height, fontSize, centerImageURI }) => {
    const theme = useTheme();
    let data = coreValueVector;

    if (shuffle) {
        data = shuffleArray(coreValueVector);;
    }
    if (maxCount) {
        data = data.slice(0, maxCount);
    }

    data = data.sort((a, b) => b.score - a.score);

    const option: EChartsOption = {
        series: [
            {
                type: 'pie',
                radius: centerImageURI != null ? ['70%', '90%'] : ['10%', '100%'],
                roseType: 'area',
                data: data.map((d) => ({ value: d.score, name: d.key })),
                itemStyle: {
                    color: (params: any) => getCoreValueColor(params.name),
                    borderRadius: 10,
                },
                label: {
                    fontFamily: theme.typography.fontFamily,
                    lineHeight: 15,
                    position: 'inner',
                    formatter: '{a|}{b|{b}}\n{c|{c}}',
                    backgroundColor: 'transparent',
                    borderWidth: 1,
                    borderRadius: 4,
                    rich: {
                        name: {
                        },
                        b: {
                            fontFamily: theme.typography.fontFamily,
                            fontSize: `${fontSize}px`,
                            color: theme.palette.primary.main,
                            lineHeight: fontSize + 2,
                        },
                        c: {
                            fontFamily: theme.typography.fontFamily,
                            fontSize: `${fontSize - 1}px`,
                            color: theme.palette.primary.dark,
                            lineHeight: fontSize,
                        },
                    }
                },
            },
        ],
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            {
                centerImageURI
                    ? <Avatar sx={{ width: 150, height: 150, position: 'absolute', boxShadow: 7, borderRadius: '50%', border: `1px solid ${theme.palette.success.light}` }}>
                        <img src={centerImageURI} alt={'img'} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} />
                    </Avatar>
                    : null
            }
            <ReactECharts
                option={option}
                style={{
                    width,
                    height,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }} />
        </Box>
    );
};

export default CoreValueChart