const ROUTES = {
    main: {
        path: '/',
    },
    survey: {
        path: 'survey',
    },
    survey_questions: {
        path: 'questions',
    },
    survey_result: {
        path: 'result',
    },
    profile: {
        path: '/profile',
    },
    friends: {
        path: '/friends',
    },
    user: {
        path: '/user',
    },
    compare_user: {
        path: '/compare',
    },
    colors: {
        path: '/colors',
    },
    privacy: {
        path: '/privacy',
    },
    terms: {
        path: '/terms',
    }
}

export default ROUTES;