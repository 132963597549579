import React from 'react';
import { Box, Button } from '@mui/material';
import { ArrowForwardIos, Group, Login, Person, Quiz, Refresh } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ROUTES from '../../utils/Routes';
import { useAuth } from '../../context/AuthContext';
import { logEvent } from '../../utils/logging';
import { useTranslation } from 'react-i18next';

type SurveyResultFooterProps = {
    isLoading: boolean,
    answerGenerated: boolean,
    hasError: boolean,
    onSubmit: () => void,
    onExit: () => void,
    openSignInModal: () => void,
};
const SurveyResultFooter: React.FC<SurveyResultFooterProps> = ({ isLoading, answerGenerated, hasError, onSubmit, onExit, openSignInModal }: SurveyResultFooterProps) => {
    const { t } = useTranslation();
    const { user, isGuest } = useAuth();
    if (hasError) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <Button color="success" variant="outlined" onClick={onSubmit} startIcon={<Refresh />} sx={{ m: 1 }}>
                    Try Again
                </Button>
            </Box>
        )
    }
    if (!answerGenerated) {
        return null;
    }
    if (isLoading) {
        return null;
    }
    return (
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" width="100%" mb={3}>
            <Button
                component={Link}
                to={ROUTES.main.path}
                variant="outlined"
                sx={{ borderRadius: 8, m: 1 }}
                startIcon={<Quiz />}
                onClick={() => {
                    logEvent({
                        category: "Survey",
                        action: "Click Try Other Tests",
                    })
                }}>
                {t("SurveyPage_TryOtherTests")}
            </Button>
            <Button
                component={Link}
                to={ROUTES.friends.path}
                variant="outlined"
                sx={{ borderRadius: 8, m: 1 }}
                startIcon={<Group />}
                onClick={() => {
                    logEvent({
                        category: "Survey",
                        action: "Click Compare With Friends",
                    })
                }}>
                {t("SurveyPage_CompareWithFriends")}
            </Button>
            {user ? <Button
                component={Link}
                to={ROUTES.profile.path}
                variant="contained"
                color="primary"
                sx={{ borderRadius: 8, m: 1 }}
                startIcon={<Person />}
                onClick={() => {
                    logEvent({
                        category: "Survey",
                        action: "Click Check My Profile",
                    });
                }}>
                {t("SurveyPage_CheckMyProfile")}
                <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
            </Button> : null}
            {isGuest ? <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: 8, m: 1 }}
                startIcon={<Login />}
                onClick={() => {
                    openSignInModal();
                    logEvent({
                        category: "Survey",
                        action: "Click Sign In For More Analysis",
                    });
                }}>
                {t("SurveyPage_SignInForMoreAnalysis")}
                <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
            </Button> : null}
        </Box>
    )
}
export default SurveyResultFooter;
