import React, { createContext, useContext, useState, useEffect } from 'react';
import { SurveyDataType } from '../utils/interfaces';
import { getSurveyCollection } from '../api/api';

type SurveyCollectionDataStateType = {
    surveyCollectionData: SurveyDataType[];
    setSurveyCollectionData: React.Dispatch<React.SetStateAction<SurveyDataType[]>>;
    loading: boolean;
};

const SurveyCollectionDataContext = createContext<SurveyCollectionDataStateType | undefined>(undefined);

export const useSurveyCollectionData = () => {
    const context = useContext(SurveyCollectionDataContext);
    if (context === undefined) {
        throw new Error('useSurveyCollectionData must be used within a SurveyCollectionDataProvider');
    }
    return context;
};

interface SurveyCollectionDataProviderProps {
    children: React.ReactNode;
}

export const SurveyCollectionDataProvider: React.FC<SurveyCollectionDataProviderProps> = ({ children }) => {
    const [surveyCollectionData, setSurveyCollectionData] = useState<SurveyDataType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchSurveys = async () => {
            try {
                setLoading(true);
                const data = await getSurveyCollection();
                setSurveyCollectionData(data);
            } catch (error) {
                console.error('Error fetching survey data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchSurveys();
    }, []);

    return (
        <SurveyCollectionDataContext.Provider value={{ surveyCollectionData, setSurveyCollectionData, loading }}>
            {children}
        </SurveyCollectionDataContext.Provider>
    );
};
