import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import TopBar from '../navigations/TopBar';
import { useAuth } from '../context/AuthContext';
import SignInComponent from '../components/auth/SignInComponent';
import ProfileComponent from '../components/profile/ProfileComponent';
import DrawerNavigationBar from '../navigations/DrawerNavigationBar';
import UserInfoForm from '../components/auth/UserInfoForm';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
    width: '100%',
});

function ProfilePage() {
    const { t } = useTranslation();
    const { user, isAuthenticated, userInfo } = useAuth();
    const [isValidUser, setIsValidUser] = useState(true);
    const [hasUserInfo, setHasUserInfo] = useState(true);

    useEffect(() => {
        setIsValidUser(isAuthenticated());
        setHasUserInfo(userInfo !== null);
    }, [user, isAuthenticated, userInfo])

    // show sign in component if not logged in
    if (!isValidUser || !user) {
        return (
            <>
                <TopBar
                    title="Ipsum AI"
                    navigations={
                        [
                            <DrawerNavigationBar />
                        ]
                    } />
                <StyledContainer
                >
                    <SignInComponent />
                </StyledContainer>
            </>
        )
    }

    // show user info input if missing user info
    if (!hasUserInfo) {
        return (
            <>
                <TopBar
                    title="Ipsum AI"
                    navigations={
                        [
                            <DrawerNavigationBar />
                        ]
                    } />
                <StyledContainer>
                    <UserInfoForm userId={user.uid} description={t('UserForm_ProfileDescription')} />
                </StyledContainer>
            </>
        )
    }

    return (
        <>
            <TopBar
                title="Ipsum AI"
                navigations={
                    [
                        <DrawerNavigationBar />
                    ]
                } />
            <StyledContainer
            >
                <ProfileComponent />
            </StyledContainer>
        </>
    );
}

export default ProfilePage;
