const DEFAULT_COLORS = ['#8AD9E1', '#FBB9D4', '#B2E2B0', '#C6E4C6', '#81CCF2', '#FCD494', '#FAD1C2'];

export const PERSONALITY_TRAIT_COLOR: { [key: string]: string } = {
    'introverted': '#79C3D680',         // Light Blue
    'extroverted': '#79C3D6',           // Light Blue
    'sensing': '#EAAAC980',             // Light Pink
    'intuitive': '#EAAAC9',             // Light Pink
    'thinking': '#9AD99F80',            // Pastel Green
    'feeling': '#9AD99F',               // Pastel Green
    'judging': '#EEB72DA0',             // Light Beige Orange
    'perceptive': '#EED2B2',            // Light Greenish Gray
    'cautious': '#70B8E580',            // Light Sky Blue
    'curious': '#97A7E590',             // Light Pastel Purple
    'passive': '#F0BD8080',             // Light Orange
    'proactive': '#F0BD80',             // Light Orange
    'consistent': '#1CB8B190',          // Light Mint
    'versatile': '#F1B8B1',             // Light Coral
    'traditional': '#8990B380',         // Light Slate Blue
    'innovative': '#0A90B390',            // Light Slate Blue
    'dependent': '#A3D9CF80',           // Light Teal
    'independent': '#A3D9CF',           // Light Teal
    'collaborative': '#60954660',       // Light Slate Gray
    'competitive': '#C51E4660',         // Salmon
    'practical': '#A9B5D980',           // Light Steel Blue
    'theoretical': '#A9B5D9',           // Light Steel Blue
    'realistic': '#F2A47780',           // Light Salmon
    'optimistic': '#21A46C80',          // Light Green
};

export const CORE_VALUE_COLOR: { [key: string]: string } = {
    'altruism': '#79C3D6',              // Light Blue
    'authenticity': '#88AAC9',          // Light Gray Blue
    'balance': '#9AD99F',               // Pastel Green
    'commitment': '#FED2B2',            // Light Orange 
    'happiness': '#DDD3E5',             // Light Stale Pink
    'courage': '#F04F4290',             // Orange
    'candor': '#E6D496',                // Mustard
    'creativity': '#2FD2B490',          // Light Slate Green
    'empathy': '#A3D9CF',               // Light Teal
    'forgiveness': '#97A76190',         // Light Olive
    'gratitude': '#A9B5D9A0',           // Light Steel Blue
    'harmony': '#A8A8DC90',             // Light Lavendar
    'humility': '#79C3D6A0',              // Light Blue
    'transparency': '#EAAAC940',          // Light Pink
    'stability': '#67CA9FE0',             // Pastel Green
    'communityship': '#B2D2B2',         // Light Greenish Gray
    'kindness': '#F0BD80A0',              // Light Orange
    'growth': '#C7132D90',              // Light Red
    'leadership': '#2380B680',          // Light Slate Blue
    'mindfulness': '#A3D9CF',           // Light Teal
    'perseverance': '#C3D71890',          // Light Yellow
    'resilience': '#A9B5D960',          // Light Steel Blue
    'responsibility': '#1D6B7790',        // Light Salmon
    'self-awareness': '#79C3D660',        // Light Blue
    'self-regulation': '#EAAAC9B0',       // Light Pink
    'service': '#9AD99FD0',               // Pastel Green
    'love': '#FF93A1C0',                // Pink
    'social-awareness': '#70B8E5D0',      // Light Sky Blue
    'spirituality': '#99BDF1A0',          // Baby Blue
    'teamwork': '#F1B8B1A0',              // Light Coral
    'trust': '#8990B340',                 // Light Slate Blue
    'visionary': '#69AEE500',           // Sky Blue
    'work-ethic': '#737DBDD0',            // Lavendar
    'justice': '#BCECFF',               // Light Steel Blue
    'integrity': '#004A7760',           // Light Blue
    'respect': '#79C3D6D0',               // Light Blue
    'family': '#16792D60',              // Light Olive
};

export const PREFERENCE_COLOR: { [key: string]: string } = {
    'sweet': '#F3C4FB80',               // Sweet - Light Purple
    'sour': '#FFD47480',                // Sour - Light Yellow
    'spicy': '#FF002350',               // Spicy - Light Red
    'savory': '#B5DB8E80',              // Savory - Light Green
    'bitter': '#74401940',              // Bitter - Light Brown
    'umami': '#E6CDA680',               // Umami - Light Tan
    'minimalist': '#EFEFEF80',          // Minimalist - Light Gray
    'vivid': '#FF7F7F80',               // Vivid - Light Red
    'abstract': '#B3E6FF80',            // Abstract - Light Blue
    'natural': '#B2D8B280',             // Natural - Light Green
    'industrial': '#A6782460',          // Industrial - Light Gold
    'romantic': '#FFB6C1A0',            // Romantic - Light Pink
    'vintage': '#FFE4C480',             // Vintage - Light Orange
    'hiking': '#94D7A680',              // Hiking - Light Green
    'swimming': '#8ACBE880',            // Swimming - Light Blue
    'running': '#F29B9B80',             // Running - Light Red
    'cycling': '#F1E7A180',             // Cycling - Light Yellow
    'sports': '#FFA50080',              // Sports - Orange
    'painting': '#FFD70080',            // Painting - Gold
    'cooking': '#D5D3EC',               // Cooking - Light Lavendar
    'dancing': '#DA001250',             // Dancing - Gray
    'photography': '#DEE1E1',         // Photography - Light Salmon
    'gardening': '#7FFF0040',           // Gardening - Light Green
    'reading': '#ADD8E680',             // Reading - Light Blue
    'video-games': '#B8860B50',         // Video Games - Dark Goldenrod
    'action': '#7D003040',              // Action - Red
    'comedy': '#FFD70050',              // Comedy - Gold
    'mystery': '#483D8B40',             // Mystery - Dark Slate Blue
    'thriller': '#8B000050',            // Thriller - Dark Red
    'fantasy': '#9370DB80',             // Fantasy - Medium Purple
    'romance': '#FF69B450',             // Romance - Light Pink
    'sci-fi': '#00CED150',              // Sci-fi - Dark Turquoise
    'documentary': '#2E8B5760',         // Documentary - Dark Sea Green
    'animation': '#00FF0050',           // Animation - Lime
    'beach': '#1A8BE970',               // Beach - Blue
    'culture': '#FFC0CB80',             // Culture - Pink
    'adventure': '#FF8C0060',           // Adventure - Dark Orange
    'history': '#DAA52080',             // History - Goldenrod
    'nature': '#00800080',              // Nature - Dark Green
    'food': '#DEE0D580',                // Food - Light Red
    'warm': '#E6551180',                // Warm - Orange Red
    'low-humidity': '#87CEEB80',        // Low Humidity - Sky Blue
    'natural-lighting': '#FFFFE080',    // Natural Lighting - Ivory
    'peaceful': '#AFEEEE60',            // Peaceful - Pale Turquoise
    'city': '#DEE0D5C0',                  // City - Gray
    'fresh-air': '#36CD9160',           // Fresh Air - Lime
    'casual': '#00808030',              // Casual - Teal
    'dressy': '#80008040',              // Dressy - Purple
    'bohemian': '#FF149320',            // Bohemian - Deep Pink
    'sporty': '#FFA50060',              // Sporty - Orange
    'edgy': '#69696950',                // Edgy - Dim Gray
    'retro': '#80000030',               // Retro - Maroon
    'classic': '#291B6250',             // Classic - Purple
    'modern': '#80800060',              // Modern - Olive
    'rustic': '#D2691E70',              // Rustic - Chocolate
    'scandinavian': '#F0FFFF80',        // Scandinavian - Azure
    'eclectic': '#FF00FF40',            // Eclectic - Fuchsia
    'coastal': '#4682B450',             // Coastal - Steel Blue
    'traditional': '#8B451360',         // Traditional - Saddle Brown
};

export const PREFERENCE_EMOJI: { [key: string]: string } = {
    'sweet': '🍬',
    'sour': '🍋',
    'spicy': '🌶️',
    'savory': '🍔',
    'bitter': '🍵',
    'umami': '🍱',
    'minimalist': '⚪️',
    'vivid': '🌈',
    'abstract': '🦄',
    'natural': '🌿',
    'industrial': '🏭',
    'romantic': '💖',
    'vintage': '📻',
    'hiking': '🥾',
    'swimming': '🏊',
    'running': '🏃',
    'cycling': '🚴',
    'sports': '⚽',
    'painting': '🎨',
    'cooking': '🍳',
    'dancing': '💃',
    'photography': '📷',
    'gardening': '🌷',
    'reading': '📚',
    'video-games': '🎮',
    'action': '💥',
    'comedy': '😂',
    'mystery': '🔍',
    'thriller': '🎬',
    'fantasy': '🧚',
    'romance': '💑',
    'sci-fi': '🚀',
    'documentary': '📽️',
    'animation': '🎞️',
    'beach': '🏖️',
    'culture': '🎭',
    'adventure': '⛺',
    'history': '🏰',
    'nature': '🌳',
    'food': '🍕',
    'warm': '🔥',
    'low-humidity': '🌵',
    'natural-lighting': '🌞',
    'peaceful': '🕊️',
    'city': '🏙️',
    'fresh-air': '🌬️',
    'casual': '👕',
    'dressy': '👗',
    'bohemian': '🌸',
    'sporty': '👖',
    'edgy': '🔪',
    'retro': '🕺',
    'classic': '🎩',
    'modern': '🏢',
    'rustic': '🌾',
    'scandinavian': '🪵',
    'eclectic': '🎵',
    'coastal': '⛵',
    'traditional': '🏡',
};

export const getPersonalityTraitRank = (key: string): number => {
    const RANK: { [key: string]: number } = {
        'introverted': 1,
        'extroverted': 2,
        'sensing': 3,
        'intuitive': 4,
        'thinking': 5,
        'feeling': 6,
        'judging': 7,
        'perceptive': 8,
        'cautious': 9,
        'curious': 10,
        'passive': 11,
        'proactive': 12,
        'consistent': 13,
        'versatile': 14,
        'traditional': 15,
        'innovative': 16,
        'dependent': 17,
        'independent': 18,
        'collaborative': 19,
        'competitive': 20,
        'practical': 21,
        'theoretical': 22,
        'realistic': 23,
        'optimistic': 24,
    }
    if (key in RANK) {
        return RANK[key]
    }
    console.log(`following personality trait does not have a rank: ${key}`)
    return 100;
}

export const getPersonalityTraitColor = (key: string): string => {
    if (key in PERSONALITY_TRAIT_COLOR) {
        return PERSONALITY_TRAIT_COLOR[key];
    }
    console.log(`following personality trait does not have a specified color: ${key}`)
    return DEFAULT_COLORS[Math.floor(Math.random() * DEFAULT_COLORS.length)];
};

export const getCoreValueColor = (key: string): string => {
    if (key in CORE_VALUE_COLOR) {
        return CORE_VALUE_COLOR[key];
    }
    console.log(`following core value does not have a specified color: ${key}`)
    return DEFAULT_COLORS[Math.floor(Math.random() * DEFAULT_COLORS.length)];
};

export const getPreferenceColor = (key: string): string => {
    if (key in PREFERENCE_COLOR) {
        return PREFERENCE_COLOR[key];
    }
    console.log(`following preference does not have a specified color: ${key}`)
    return 'rgba(0,0,0,0.13)';
};
