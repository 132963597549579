import React from 'react';
import { Box, useTheme } from '@mui/material';
import { EvaluationVector } from '../../utils/interfaces';
import ReactECharts from './ReactECharts';
import type { EChartsOption } from "echarts";
import { getPersonalityTraitColor, getPersonalityTraitRank } from '../../utils/chartUtils';

interface PersonalityVectorChartProps {
    personalityTraitVector: EvaluationVector;
    width: string;
    height: string;
}

type ToolTipParamType = {
    name: string,
    data: {
        value: number,
    },
}[]

const PersonalityTraitVectorChart: React.FC<PersonalityVectorChartProps> = ({
    personalityTraitVector,
    width,
    height,
}) => {
    let pVector = personalityTraitVector.sort((a, b) => getPersonalityTraitRank(b.key) - getPersonalityTraitRank(a.key));
    const theme = useTheme();
    // when user did not generate personality vector yet
    const labelRight = {
        position: 'right' as 'right',
    };
    const labelLeft = {
        position: 'left' as 'left',
    };

    const option: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {},
            formatter: (params) => {
                const parsedParams = params as ToolTipParamType;
                return `${parsedParams[0].name}: ${Math.abs(parsedParams[0].data.value)}%`;
            },
        },
        grid: {
            top: 30,
            bottom: 30,
            containLabel: true,
        },
        textStyle: {
            fontFamily: theme.typography.fontFamily
        },
        xAxis: {
            type: 'value',
            min: -100,
            max: 100,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
                formatter: (value: any) => {
                    return '';
                },
            },
        },
        yAxis: {
            type: 'category',
            axisLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            data: pVector.map((val) => val.key),
        },
        series: [
            {
                name: 'Value',
                type: 'bar',
                stack: 'Total',
                label: {
                    show: true,
                    formatter: '{b}',
                    fontSize: '8px',
                },
                itemStyle: {
                    color: (params: any) => getPersonalityTraitColor(params.name),
                    borderRadius: 5,
                },
                data: pVector.map((val) => {
                    const isLeft = getPersonalityTraitRank(val.key) % 2 === 0;
                    return {
                        value: isLeft ? val.score * -1 : val.score,
                        label: isLeft ? labelRight : labelLeft,
                    };
                }),
            },
        ],
    };

    return (
        <Box>
            <ReactECharts
                option={option}
                style={{
                    width,
                    height,
                }}
            />
        </Box>
    );
};

export default PersonalityTraitVectorChart;
