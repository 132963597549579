import React, { ReactNode, useEffect, useState, useMemo, useCallback } from 'react';
import AuthContext, { AuthContextData } from '../context/AuthContext';
import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword, onAuthStateChanged, User, signOut, FacebookAuthProvider, createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../utils/firebaseConfig';
import { readUserInfo } from '../api/firestore';
import { collection, orderBy, query, onSnapshot, doc } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { PersonaSchema, SurveyResponseType, UserInfo } from '../utils/interfaces';

interface AuthProviderProps {
    children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [userPersona, setUserPersona] = useState<PersonaSchema | null>(null);
    const [completedSurveys, setCompletedSurveys] = useState<SurveyResponseType[]>([]);
    const [isGuest, setIsGuest] = useState(false);

    // read user's all survey results from firestore
    const readAllSurveyResults = async (uid: string): Promise<() => void> => {
        try {
            const userSurveyResultsRef = collection(db, "surveys", uid, "results");
            const userSurveyResultsQuery = query(userSurveyResultsRef, orderBy("timestamp", "desc"));

            // Use onSnapshot instead of getDocs to subscribe to changes in real-time
            const unsubscribe = onSnapshot(userSurveyResultsQuery, (querySnapshot) => {
                const allSurveyAnswers: SurveyResponseType[] = [];
                querySnapshot.forEach((doc) => {
                    allSurveyAnswers.push(doc.data() as SurveyResponseType);
                });
                setCompletedSurveys(allSurveyAnswers);
            });

            return unsubscribe;

        } catch (error) {
            console.error("Error retrieving survey answers: ", error);
            return () => { };
        }
    };

    // read user's persona result from firestore
    const readPersonaResult = async (uid: string): Promise<() => void> => {
        try {
            const userPersonaRef = doc(db, "persona", uid);
            // Subscribe to changes in real-time using onSnapshot
            const unsubscribe = onSnapshot(userPersonaRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.data() as PersonaSchema;
                    setUserPersona(data);
                } else {
                    setUserPersona(null);
                }
            });
            // Return the unsubscribe function for cleanup
            return unsubscribe;
        } catch (error) {
            console.error("Error retrieving persona result: ", error);
            return () => { };
        }
    };

    // Call readAllSurveyResults inside the useEffect hook
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setUser(user);
            if (user) {
                const userInfo = await readUserInfo(user.uid);
                setUserInfo(userInfo);
                // call all subscription functions;
                const unsubscribeAllSurveyFunc = await readAllSurveyResults(user.uid);
                const unsubscribePersona = await readPersonaResult(user.uid);
                // return a function from useEffect to clean up the subscription when the component unmounts
                return () => {
                    unsubscribeAllSurveyFunc();
                    unsubscribePersona();
                };
            } else {
                setUserInfo(null);
                setCompletedSurveys([]);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const setUserInfoManually = (userInfo: UserInfo) => {
        setUserInfo(userInfo);
    }

    const isAuthenticated = () => {
        return user !== null;
    };

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
        setIsGuest(false);
    };

    const signInWithFacebook = async () => {
        const provider = new FacebookAuthProvider();
        await signInWithPopup(auth, provider);
        setIsGuest(false);
    };

    const signInWithEmailAndPassword = async (email: string, password: string) => {
        await firebaseSignInWithEmailAndPassword(auth, email, password);
        setIsGuest(false);
    };

    const signUpWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
    };

    const signUpWithFacebook = async () => {
        const provider = new FacebookAuthProvider();
        await signInWithPopup(auth, provider);
    };

    const signUpWithEmailAndPassword = async (email: string, password: string) => {
        await firebaseCreateUserWithEmailAndPassword(auth, email, password);
    };

    const logout = async () => {
        await signOut(auth);
    };

    // Define the memoized functions using useCallback
    const setUserInfoManuallyMemoized = useCallback(setUserInfoManually, []);
    const isAuthenticatedMemoized = useCallback(isAuthenticated, [user]);
    const signInWithGoogleMemoized = useCallback(signInWithGoogle, []);
    const signInWithFacebookMemoized = useCallback(signInWithFacebook, []);
    const signInWithEmailAndPasswordMemoized = useCallback(signInWithEmailAndPassword, []);
    const signUpWithGoogleMemoized = useCallback(signUpWithGoogle, []);
    const signUpWithFacebookMemoized = useCallback(signUpWithFacebook, []);
    const signUpWithEmailAndPasswordMemoized = useCallback(signUpWithEmailAndPassword, []);
    const logoutMemoized = useCallback(logout, []);

    // Use useMemo to memoize the value object
    const value: AuthContextData = useMemo(
        () => ({
            user,
            userInfo,
            userPersona,
            completedSurveys,
            isGuest,
            setIsGuest,
            setUserInfoManually: setUserInfoManuallyMemoized,
            isAuthenticated: isAuthenticatedMemoized,
            signInWithGoogle: signInWithGoogleMemoized,
            signInWithFacebook: signInWithFacebookMemoized,
            signInWithEmailAndPassword: signInWithEmailAndPasswordMemoized,
            signUpWithGoogle: signUpWithGoogleMemoized,
            signUpWithFacebook: signUpWithFacebookMemoized,
            signUpWithEmailAndPassword: signUpWithEmailAndPasswordMemoized,
            logout: logoutMemoized,
        }),
        [
            user,
            userInfo,
            userPersona,
            completedSurveys,
            isGuest,
            setIsGuest,
            setUserInfoManuallyMemoized,
            isAuthenticatedMemoized,
            signInWithGoogleMemoized,
            signInWithFacebookMemoized,
            signInWithEmailAndPasswordMemoized,
            signUpWithGoogleMemoized,
            signUpWithFacebookMemoized,
            signUpWithEmailAndPasswordMemoized,
            logoutMemoized,
        ]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
