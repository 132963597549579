import { Box, Typography, Button } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UserFriend, UserFriendRequest, UserInfo } from "../../utils/interfaces";
import { getFriendRequests, acceptFriendRequest, declineFriendRequest } from "../../api/friends_api";
import { User } from "@firebase/auth";
import { logEvent } from "../../utils/logging";
import { GRADIENT_COLORS } from "../../const/gradientColors";

interface FriendRequestsComponentProps {
    user: User
    userInfo: UserInfo,
    friends: UserFriend[],
    setFriends: Dispatch<SetStateAction<UserFriend[]>>,
}

const FriendRequestsComponent: React.FC<FriendRequestsComponentProps> = ({ user, friends, setFriends }) => {
    const [friendRequests, setFriendRequests] = useState<UserFriendRequest[]>([]);

    useEffect(() => {
        const fetchFriendRequests = async () => {
            if (!user) return;
            const friendRequests = await getFriendRequests(user.uid);
            setFriendRequests(friendRequests);
        };
        (async () => {
            await fetchFriendRequests();
        })();
    }, [user]);

    const handleAccept = async (request: UserFriendRequest) => {
        await acceptFriendRequest(request.requestId);
        setFriendRequests(friendRequests.filter(r => r.requestId !== request.requestId));
        setFriends([...friends, {
            userId: request.senderId,
            displayName: request.senderName,
            connectionId: '',
            since: ''
        } as UserFriend])
        logEvent({
            category: "Friends",
            action: "Click Accept Friend Request",
        })
    }

    const handleDecline = async (request: UserFriendRequest) => {
        await declineFriendRequest(request.requestId);
        setFriendRequests(friendRequests.filter(r => r.requestId !== request.requestId));
        logEvent({
            category: "Friends",
            action: "Click Decline Friend Request",
        })
    }

    if (friendRequests.length === 0) {
        return null;
    }

    return (
        <Box m={2} mt={0} mb={0} width="100%" display="flex" flexDirection="column" alignItems="center">
            <Box m={2} mb={0} width="100%" flexGrow={1} maxWidth="400px">
                <Box display="flex" pl={3} >
                    <Typography variant="subtitle1">Friend Requests</Typography>
                </Box>
                {friendRequests.map((request, index) => (
                    <Box key={`friend_request_${index}`} display="flex" m={2} p={1} sx={{ borderRadius: 3, border: '1px solid #8b95b150', backgroundImage: GRADIENT_COLORS.pastel_secondary }}>
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-around" flexGrow={1}>
                            <Box>
                                <Typography variant="subtitle1" color="textPrimary">
                                    {request.senderName} <Typography variant="caption">({request.senderId.slice(0, 5)})</Typography><br />
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {`Sent on: ${new Date(request.timestamp).toLocaleString()}`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" m={1}>
                            <Button sx={{ m: 0.5, fontSize: 10 }} variant="outlined" onClick={() => handleAccept(request)}>Accept</Button>
                            <Button sx={{ m: 0.5, fontSize: 10 }} variant="outlined" onClick={() => handleDecline(request)}>Decline</Button>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default FriendRequestsComponent;