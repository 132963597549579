// src/components/SignUpComponent.tsx

import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
// import FacebookIcon from '@mui/icons-material/Facebook';
// import { Google } from "@mui/icons-material";
import { useState } from "react";
import { logEvent } from "../../utils/logging";

interface SignUpProps {
    callback: () => void;
}

const SignUpComponent: React.FC<SignUpProps> = ({ callback }) => {
    const { signUpWithEmailAndPassword } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");


    const handleEmailSignUp = async () => {
        try {
            await signUpWithEmailAndPassword(email, password);
            logEvent({
                category: "Authentication",
                action: "Sign Up",
                label: 'email',
            })
            if (callback) callback();
        } catch (error: any) {
            console.error("Error signing up with email:", error);
            setError(error.message.toString());
            logEvent({
                category: "Authentication",
                action: "Sign Up Fail",
                label: 'email',
            })
        }
    };

    // const handleGoogleSignUp = async () => {
    //     try {
    //         await signUpWithGoogle();
    //         logEvent({
    //             category: "Authentication",
    //             action: "Sign Up",
    //             label: 'google',
    //         })
    //         if (callback) callback();
    //     } catch (error) {
    //         console.error('Error signing up with Google:', error);
    //         logEvent({
    //             category: "Authentication",
    //             action: "Sign Up Fail",
    //             label: 'google',
    //         })
    //     }
    // };

    // const handleFacebookSignUp = async () => {
    //     try {
    //         await signUpWithFacebook();
    //         logEvent({
    //             category: "Authentication",
    //             action: "Sign Up",
    //             label: 'facebook',
    //         })
    //         if (callback) callback();
    //     } catch (error) {
    //         console.error('Error signing up with Facebook:', error);
    //         logEvent({
    //             category: "Authentication",
    //             action: "Sign Up Fail",
    //             label: 'facebook',
    //         })
    //     }
    // };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ pt: 7, pb: 7 }} width="300px">
            <Box p={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h6">
                    Sign up to see more
                </Typography>
                <Typography variant="caption">
                    Discover Your True Self
                </Typography>
            </Box>
            <Box width="200px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    required
                    fullWidth
                    margin="dense"
                />
                <TextField
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    required
                    fullWidth
                    margin="dense"
                />
                <Button color="secondary" variant="contained" onClick={handleEmailSignUp} sx={{ m: 1, alignItems: "center" }} fullWidth>
                    Continue
                </Button>
                {error && (
                    <Typography variant="caption" color="error">
                        {error}
                    </Typography>
                )}
                {/* <Typography variant="caption" p={1}> <b>OR</b> </Typography>
                <Button variant="outlined" startIcon={<Google />} onClick={handleGoogleSignUp} sx={{ m: 1, alignItems: 'center', fontSize: '9px' }} fullWidth>
                    Continue with Google
                </Button>
                <Button variant="outlined" startIcon={<FacebookIcon />} onClick={handleFacebookSignUp} sx={{ m: 1, alignItems: 'center', fontSize: '9px' }} fullWidth >
                    Continue with Facebook
                </Button> */}
            </Box>
            <Divider variant="fullWidth" sx={{ width: '250px', m: 1 }} />
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Typography variant="body2" sx={{ m: 1 }}>
                    Already have an account?{" "}
                </Typography>
                <Typography
                    variant="caption"
                    onClick={callback}
                    sx={{ textDecoration: 'underline', cursor: 'pointer', color: 'primary.main' }}>
                    <b>Sign In</b>
                </Typography>
            </Box>
        </Box >
    )
}

export default SignUpComponent;

