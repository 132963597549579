import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface CountdownClockProps {
  targetTimestamp: Date,
  actionComponent: React.ReactElement;
}

const CountdownClock: React.FC<CountdownClockProps> = ({ targetTimestamp, actionComponent }) => {
  const [remainingTime, setRemainingTime] = useState({ minutes: 0, seconds: 0 });
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (isComplete) return;
    if (!targetTimestamp.getTime()) return;
    const updateRemainingTime = () => {
      const currentTime = new Date().getTime();
      const targetTime = targetTimestamp.getTime();
      const remainingTimeInSeconds = Math.max(Math.floor((targetTime - currentTime) / 1000), 0);
      const minutes = Math.floor((remainingTimeInSeconds % 3600) / 60);
      const seconds = remainingTimeInSeconds % 60;
      setRemainingTime({ minutes, seconds });
      if (remainingTimeInSeconds === 0) {
        setIsComplete(true);
      }
    };

    // Update the remaining time every second
    updateRemainingTime();
    const intervalId = setInterval(updateRemainingTime, 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [targetTimestamp, isComplete]);

  if (isComplete) {
    return (
      <Box>
        {actionComponent}
      </Box>
    )
  }

  return (
    <Box marginTop={2}>
      <Box>
        <Typography variant="caption">try again in</Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" sx={{ p: 1 }}>
          <Typography variant="subtitle1">{remainingTime.minutes}</Typography>
          <Typography variant="caption">Minutes</Typography>
        </Box>
        <Box display="flex" flexDirection="column" sx={{ p: 1 }}>
          <Typography variant="subtitle1">{remainingTime.seconds}</Typography>
          <Typography variant="caption">Seconds</Typography>
        </Box>
      </Box>
    </Box>
  )
};

export default CountdownClock;