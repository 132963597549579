import { Avatar, Box, Divider, Typography } from "@mui/material";
import { UserFriend, UserInfo } from "../../utils/interfaces";
import { User } from "@firebase/auth";
import ROUTES from "../../utils/Routes";
import { Link } from "react-router-dom";
import { logEvent } from "../../utils/logging";
import { ArrowForwardIos } from "@mui/icons-material";

interface FriendsListComponentProps {
    user: User,
    userInfo: UserInfo,
    friends: UserFriend[],
}

const FriendsListComponent: React.FC<FriendsListComponentProps> = ({ user, friends }) => {
    if (friends.length === 0) {
        return (
            <Box p={5} pt={10} pb={10}>
                <Typography>
                    You have not added your friends yet.<br />
                </Typography>
                <Typography mt={2}>
                    Start adding your friends to compare your personas!
                </Typography>
            </Box>
        )
    }

    return (
        <Box m={2} width="100%" display="flex" flexDirection="column" alignItems="center">
            <Box m={2} width="100%" flexGrow={1} maxWidth="400px">
                <Box display="flex" pl={3} >
                    <Typography variant="subtitle1">My Friends</Typography>
                </Box>
                {friends.map((friend, index) => (
                    <Box key={`friends_list_${index}`} >
                        <Box m={2} p={1}>
                            <Link
                                to={`${ROUTES.user.path}/${friend.userId}`}
                                onClick={() => logEvent({
                                    category: "Friends",
                                    action: "Click Friend Profile",
                                })}
                                style={{
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Box display="flex" flexDirection="row" flexGrow={1} alignItems="center">
                                    <Avatar variant="rounded" />
                                    <Box display="flex" flexDirection="column" pl={4}>
                                        <Box display="flex" flexDirection="row" alignItems="baseline" >
                                            <Typography variant="h6" color="primary" fontWeight={500}>
                                                {friend.displayName}
                                            </Typography>
                                            <Typography variant="caption" color="primary" pl={1}>({friend.userId.slice(0, 5)})</Typography>
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <Typography variant="subtitle2" color="primary">
                                                {friend.title ?? 'unknown'}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <Typography variant="caption" color="primary.light">
                                                {`Friends since: ${new Date(friend.since).toLocaleDateString()}`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <ArrowForwardIos color="info" />
                            </Link>
                        </Box>
                        <Divider sx={{ width: "100%" }} />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default FriendsListComponent;
