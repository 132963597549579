import React from 'react';
import { Box, Button, Typography } from '@mui/material';

type SurveyResultHeaderProps = {
    title: string,
    iconImageSrc: string,
    answerGenerated: boolean,
    isLoading: boolean,
    hasError: boolean,
    onSubmit: () => void,
};

const SurveyResultHeader: React.FC<SurveyResultHeaderProps> = ({ title, iconImageSrc, answerGenerated, hasError, isLoading, onSubmit }: SurveyResultHeaderProps) => {
    if (isLoading || answerGenerated) {
        return null;
    }
    return (
        <Box display="flex" justifyContent="center" alignItems="center" mt={17}>
            <Box flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant='h5' color="primary" sx={{ textAlign: 'center', p: 2 }}>
                    {title}
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                    <Box
                        borderRadius={8}
                        height="150px"
                        width="150px"
                        sx={{
                            backgroundImage: `url(${iconImageSrc})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}
                    />
                </Box>
                {!answerGenerated && !hasError
                    ? <Box display="flex" justifyContent="center" alignItems="center" p={3}>
                        <Button color="primary" variant="outlined" onClick={onSubmit} sx={{ borderRadius: 8, p: 2, border: 'hidden', backgroundImage: 'linear-gradient(to bottom right, #63CFB190 0%, #2B9C32C0 100%)', color: 'white', minWidth: '200px' }}>
                            Get Your Result
                        </Button>
                    </Box>
                    : null
                }
            </Box>
        </Box >
    )
}

export default SurveyResultHeader;
