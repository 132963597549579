import { createContext, useContext } from 'react';
import { User } from 'firebase/auth';
import { PersonaSchema, SurveyResponseType, UserInfo } from '../utils/interfaces';

export interface AuthContextData {
    // isLoading: boolean,
    user: User | null;
    userInfo: UserInfo | null;
    userPersona: PersonaSchema | null;
    completedSurveys: SurveyResponseType[];
    isGuest: boolean,
    setIsGuest: React.Dispatch<React.SetStateAction<boolean>>,
    setUserInfoManually: (userInfo: UserInfo) => void;
    isAuthenticated: () => boolean;
    signInWithGoogle: () => Promise<void>;
    signInWithFacebook: () => Promise<void>;
    signInWithEmailAndPassword: (email: string, password: string) => Promise<void>;
    signUpWithGoogle: () => Promise<void>;
    signUpWithFacebook: () => Promise<void>;
    signUpWithEmailAndPassword: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextData | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthContext;
