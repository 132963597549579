import { EvaluationVector } from "../utils/interfaces";

export const SAMPLE_PERSONALITY_TRAIT_VECTOR: EvaluationVector = [
    {
        "key": "extroverted",
        "score": 64
    },
    {
        "key": "intuitive",
        "score": 73
    },
    {
        "key": "thinking",
        "score": 54
    },
    {
        "key": "judging",
        "score": 70
    },
    {
        "key": "proactive",
        "score": 79
    },
    {
        "key": "collaborative",
        "score": 44
    },
]

export const SAMPLE_CORE_VALUES_VECTOR: EvaluationVector = [
    {
        "key": "authenticity",
        "score": 52
    },
    {
        "key": "commitment",
        "score": 72
    },
    {
        "key": "empathy",
        "score": 53
    },
    {
        "key": "visionary",
        "score": 67
    },
    {
        "key": "growth",
        "score": 71
    },
    {
        "key": "integrity",
        "score": 57
    },
    {
        "key": "respect",
        "score": 80
    }
]

export const SAMPLE_QUOTES = [
    "I am always seeking new adventures and experiences.",
    "Commitment is the most important thing in life.",
    "Personal growth is a never-ending journey.",
    "I believe in pursuing knowledge and wisdom.",
];

export const SAMPLE_USER_COMPARISON_DATA = {
    "data": [
        {
            "name": "You",
            "persona": {
                "quotes": [
                    "I am always seeking new adventures and experiences.",
                    "Love is the most important thing in life.",
                    "Personal growth is a never-ending journey.",
                    "I believe in pursuing knowledge and wisdom.",
                    "Creating joy for others brings me the greatest satisfaction."
                ],
                "title": "Ambitious Leader",
                "description": "You are a highly ambitious individual who seeks personal growth and learning. You thrive in dynamic and challenging environments, and approach difficult situations with a logical and harmonious resolution. Your thoughtfulness and perception are traits that you are proud of, and you value affection and connection above all else. You admire leadership qualities such as compassion and empathy, and resonate deeply with the virtue of love. You enjoy expressing yourself through creative activities and seek to create joy for yourself and others. You are an extroverted individual who feels most comfortable in modern city environments.",
            },
            "vVec": [
                {
                    "key": "love",
                    "score": 80
                },
                {
                    "score": 70,
                    "key": "creativity"
                },
                {
                    "key": "empathy",
                    "score": 60
                },
                {
                    "score": 50,
                    "key": "leadership"
                },
                {
                    "key": "growth",
                    "score": 40
                },
                {
                    "score": 30,
                    "key": "self-awareness"
                }
            ],
            "pVec": [
                {
                    "score": 30,
                    "key": "innovative"
                },
                {
                    "key": "consistent",
                    "score": 40
                },
                {
                    "score": 50,
                    "key": "curious"
                },
                {
                    "key": "perceptive",
                    "score": 60
                },
                {
                    "key": "feeling",
                    "score": 70
                },
                {
                    "key": "extroverted",
                    "score": 80
                }
            ],
            "keywords": [
                "love",
                "creativity",
                "empathy",
                "leadership",
                "curious"
            ]
        },
        {
            "name": "Your Friend",
            "persona": {
                "title": "Empathetic Peacemaker",
                "description": "You are a driven and goal-oriented individual who values family and social connections. You are adaptable and empathetic, able to handle difficult situations with understanding and support. You feel most comfortable in a modern city environment and enjoy socializing with friends. Your favorite genre is romance and you feel most connected to dolphins. Intelligence is a quality you find attractive in a romantic partner. When things don't go your way, you try to fix the problem. Your favorite season is fall and you enjoy listening to pop music. In a group, you tend to be the peacemaker.",
                "timestamp": "Sat May 13 2023 23:40:14 GMT-0700 (Pacific Daylight Time)",
                "quotes": [
                    "I believe in setting high goals and working hard to achieve them.",
                    "Family is everything to me, they are my support system.",
                    "I love spending time with my friends, they bring me joy and laughter.",
                    "Empathy and understanding are key to resolving conflicts.",
                    "I find intelligence to be incredibly attractive, it's a sign of a curious mind.",
                    "Fall is my favorite season, I love the colors and the crisp air.",
                    "Pop music always puts me in a good mood.",
                    "Dolphins are such intelligent and social creatures, I feel a connection to them.",
                    "I try to stay calm and focused when faced with stress, seeking support when needed."
                ]
            },
            "vVec": [
                {
                    "key": "family",
                    "score": 80
                },
                {
                    "key": "empathy",
                    "score": 70
                },
                {
                    "key": "kindness",
                    "score": 60
                },
                {
                    "score": 50,
                    "key": "creativity"
                },
                {
                    "score": 40,
                    "key": "teamwork"
                },
                {
                    "key": "growth",
                    "score": 30
                }
            ],
            "pVec": [
                {
                    "score": 30,
                    "key": "consistent"
                },
                {
                    "score": 50,
                    "key": "cautious"
                },
                {
                    "key": "traditional",
                    "score": 40
                },
                {
                    "key": "perceptive",
                    "score": 60
                },
                {
                    "key": "feeling",
                    "score": 80
                },
                {
                    "score": 70,
                    "key": "introverted"
                }
            ],
            "keywords": [
                "family",
                "empathy",
                "kindness",
                "cautious",
                "curious"
            ]
        }
    ],
    "comparisonResult": {
        "similarities": [
            "Both have strong sense of empathy towards others.",
            "Both highly value honesty and integrity their lives.",
            "Both They share a similar level of openness to new experiences and ideas.",
        ],
        "differences": [
            "You tend to be more extroverted, while Your Friend is more introverted.",
            "Your Friend places a higher value on stability, while You are more open to innovation.",
            "You value professional growth, while your Friend values relationships",
        ]
    }
}