import React from 'react';
import { Box, useTheme } from '@mui/material';
import { EvaluationVector } from '../../utils/interfaces';
import ReactECharts from './ReactECharts';
import type { EChartsOption } from "echarts";
import { getPersonalityTraitColor, getPersonalityTraitRank } from '../../utils/chartUtils';

interface PersonalityVectorRadarChartProps {
    personalityTraitVector: EvaluationVector;
    width: string;
    height: string;
    fontSize: number,
}

const PersonalityTraitVectorRadarChart: React.FC<PersonalityVectorRadarChartProps> = ({
    personalityTraitVector,
    width,
    height,
    fontSize,
}) => {
    let pVector = personalityTraitVector.sort((a, b) => getPersonalityTraitRank(b.key) - getPersonalityTraitRank(a.key));

    const theme = useTheme();
    const option: EChartsOption = {
        // color: ['#67F9D8', 'black', '#56A3F1', '#FF917C'],
        tooltip: {
            trigger: 'item',
            textStyle: {
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.secondary.light,
                fontSize,
            }
        },
        radar: {
            startAngle: 90,
            splitNumber: 4,
            shape: 'circle',
            radius: '60%',
            center: ['50%', '50%'],
            indicator: pVector.map((val) => ({
                name: val.key,
                max: 120,
                color: getPersonalityTraitColor(val.key),
            })),
            axisName: {
                backgroundColor: 'rgba(0,0,0,0.03)',
                borderRadius: 5,
                padding: [4, 5],
                fontSize: '15px',
                fontWeight: 540,
            },
            splitArea: {
                areaStyle: {
                    shadowColor: theme.palette.primary.main,
                    shadowBlur: 30
                }
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(150, 200, 250, 0.4)',
                }
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(150, 150, 150, 0.2)',
                }
            },
        },
        series: [
            {
                name: "Personality",
                type: 'radar',
                data: [
                    {
                        value: pVector.map((val) => Math.abs(val.score)),
                        itemStyle: {
                            color: theme.palette.info.main,
                            borderColor: theme.palette.primary.light,
                        },
                        symbol: 'circle',
                        symbolSize: 6,
                    },
                ],
            },
        ],
    };

    return (
        <Box>
            <ReactECharts
                option={option}
                style={{
                    width,
                    height,
                }}
            />
        </Box>
    );
};

export default PersonalityTraitVectorRadarChart;
