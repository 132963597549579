import React from 'react';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { EvaluationVector, SurveyEvaluationType } from '../../utils/interfaces';
import CoreValueChart from '../charts/CoreValueChart';
import CircularProgressWithText from '../CircularProgressWithText';
import PersonalityTraitVectorRadarChart from '../charts/PersonalityTraitVectorRadarChart';
import PreferencesChart from '../charts/PreferencesChart';

type SurveyResultContentProps = {
    surveyEvaluation: SurveyEvaluationType | null,
    surveyEvaluationVector: EvaluationVector,
    isLoading: boolean,
    error: string,
};

type SurveyResultContentChartProps = {
    surveyEvaluation: SurveyEvaluationType,
    surveyEvaluationVector: EvaluationVector,
};

const SurveyResultContentChart: React.FC<SurveyResultContentChartProps> = ({ surveyEvaluation, surveyEvaluationVector }) => {
    const theme = useTheme();
    if (surveyEvaluation.type === 'core_value') {
        return (
            <>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={2}>
                    <CoreValueChart coreValueVector={surveyEvaluationVector} sort={true} shuffle={false} maxCount={8} width="300px" height="300px" fontSize={12} centerImageURI={surveyEvaluation.imageURI} />
                </Box>
            </>
        )
    }
    if (surveyEvaluation.type === 'personality_trait') {
        return (
            <>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Avatar sx={{ position: 'absolute', width: 130, height: 130, boxShadow: 7, borderRadius: '50%', border: `1px solid ${theme.palette.success.light}` }}>
                        <img src={surveyEvaluation.imageURI} alt={'img'} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} />
                    </Avatar>
                    <PersonalityTraitVectorRadarChart personalityTraitVector={surveyEvaluationVector} width="350px" height="300px" fontSize={13} />
                </Box>
            </>
        )
    }
    if (surveyEvaluation.type === 'preference') {
        return (
            <>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={2}>
                    <Avatar variant="rounded" sx={{ width: 200, height: 200, boxShadow: 7, borderRadius: 5, border: `1px solid ${theme.palette.success.light}`, m: 2 }}>
                        <img src={surveyEvaluation.imageURI} alt={'img'} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Avatar>
                </Box>
                <Box display="flex" flexDirection="row" flexWrap="wrap" margin={1} textAlign="justify">
                    <PreferencesChart preferenceVector={surveyEvaluationVector} />
                </Box>
            </>
        )
    }
    return null;
}

const SurveyResultContent: React.FC<SurveyResultContentProps> = ({ surveyEvaluation, surveyEvaluationVector, isLoading, error }: SurveyResultContentProps) => {
    if (error !== '') {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                <Typography color="error" sx={{ whiteSpace: 'pre-line', m: 5, textAlign: 'center' }}>
                    {error}
                </Typography>
            </Box>
        )
    }
    if (isLoading === true) {
        return <Box display="flex" alignItems="center" justifyContent="center" height="90vh">
            <CircularProgressWithText />
        </Box>;
    }
    if (surveyEvaluation == null || surveyEvaluation.title === '') {
        return null;
    }
    return (
        <Box display="flex" flexDirection="column" alignItems="center" p={3}>
            <Box textAlign="center" mt={1}>
                <Typography variant="h4" fontFamily="'Fredericka the Great', cursive;" sx={{ whiteSpace: 'pre-line' }}>{surveyEvaluation.title}</Typography>
                <Typography variant="subtitle1" color="grey" sx={{ p: 1, whiteSpace: 'pre-line' }}>{surveyEvaluation.subtitle}</Typography>
            </Box>
            <SurveyResultContentChart surveyEvaluation={surveyEvaluation} surveyEvaluationVector={surveyEvaluationVector} />
            <Box m={2} textAlign="justify" maxWidth="500px">
                <Typography variant="caption">
                    {surveyEvaluation.reason}
                </Typography>
            </Box>
        </Box>
    )
}

export default SurveyResultContent;
