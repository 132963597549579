import React from 'react';
import { Box, Typography } from '@mui/material';
import { EvaluationVector } from '../../utils/interfaces';
import { PREFERENCE_EMOJI, getPreferenceColor } from '../../utils/chartUtils';

interface PersonaComponentProps {
    preferenceVector: EvaluationVector,
}

const PreferencesChart: React.FC<PersonaComponentProps> = ({ preferenceVector }) => {
    const preferences = preferenceVector.map((preference) => preference.key);
    return (
        <>
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center" textAlign="justify" maxWidth="300px" mb={3}>
                {
                    preferences.map(
                        (preference) => {
                            return (
                                <Typography key={preference} variant="caption" height="30px">
                                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ borderRadius: 2, backgroundColor: getPreferenceColor(preference), height: '22px', p: 1, m: 0.3 }}>
                                        <Typography variant="caption" fontSize='10px' pr={0.5}>
                                            {PREFERENCE_EMOJI[preference]}
                                        </Typography>
                                        <Typography variant="caption" fontSize='7px'>
                                            {preference}
                                        </Typography>
                                    </Box>
                                </Typography>
                            )
                        }
                    )
                }
            </Box>
        </>
    )
}

export default PreferencesChart;
