import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import TopBar from '../navigations/TopBar';
import { useAuth } from '../context/AuthContext';
import SignInComponent from '../components/auth/SignInComponent';
import DrawerNavigationBar from '../navigations/DrawerNavigationBar';
import { PersonaSchema, SurveyResponseType } from '../utils/interfaces';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { searchExactUser } from '../api/friends_api';
import CoreValueChart from '../components/charts/CoreValueChart';
import ROUTES from '../utils/Routes';
import PersonalityTraitVectorChart from '../components/charts/PersonalityTraitVectorChart';
import { logEvent } from '../utils/logging';
import PersonaDescription from '../components/profile/PersonaDescription';
import PersonaKeywords from '../components/profile/PersonaKeywords';
import { ArrowForwardIos } from '@mui/icons-material';
import ProfileSurveyAnswers from '../components/profile/ProfileSurveyAnswers';

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
    width: '100%',
});

function UserPage() {
    const { user, isAuthenticated, userInfo, userPersona } = useAuth();
    let navigate = useNavigate();

    const [isValidUser, setIsValidUser] = useState(false);
    const { userId } = useParams(); // Get userId from URL parameter
    const [targetUserDisplayName, setTargetUserDisplayName] = useState<string>('');
    const [targetUserPersona, setTargetUserPersona] = useState<PersonaSchema | null>(null);
    const [targetUserCompletedSurveys, setTargetUserCompletedSurveys] = useState<SurveyResponseType[] | null>(null);
    // authenticate viewer
    useEffect(() => {
        if (!user) return;
        setIsValidUser(isAuthenticated());
        const getTargetUser = async () => {
            if (!userId) return;
            const targetUser = await searchExactUser(userId);
            setTargetUserDisplayName(targetUser.displayName);
            setTargetUserPersona(targetUser.persona);
            setTargetUserCompletedSurveys(targetUser.completedSurveys);
        };
        getTargetUser();
    }, [user, isAuthenticated, userInfo, userId])

    // show sign in component if not logged in
    if (!isValidUser || !user) {
        return (
            <>
                <TopBar
                    title="Ipsum AI"
                    navigations={
                        [
                            <DrawerNavigationBar />
                        ]
                    } />
                <StyledContainer
                >
                    <SignInComponent />
                </StyledContainer>
            </>
        )
    }

    if (userPersona == null) {
        return (
            <>
                <TopBar
                    title="Ipsum AI"
                    navigations={
                        [
                            <DrawerNavigationBar />
                        ]
                    } />
                <StyledContainer>
                    <Box height="80vh" display="flex" flexDirection="column" justifyContent="center">
                        <Typography maxWidth="300px" variant="caption" p={3}> You must analyze your <b> persona </b> <br /> before seeing your friend's profile </Typography>
                        <Box>
                            <Button component={Link} to={ROUTES.profile.path} variant="contained" color="primary" sx={{ borderRadius: 3 }}>
                                Go to Profile Page
                                <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
                            </Button>
                        </Box>
                    </Box>
                </StyledContainer>
            </>
        )
    }

    // return 404 not found page if target userId is invalid
    if (targetUserPersona == null || targetUserPersona.coreValueVector == null || targetUserPersona.personalityTraitVector == null) {
        return (
            <><TopBar
                title="Ipsum AI"
                navigations={
                    [
                        <DrawerNavigationBar />
                    ]
                } />
                <StyledContainer>
                    <Box height="80vh" display="flex" flexDirection="column" justifyContent="center">
                        <Typography p={3} variant='subtitle2'><b>{targetUserDisplayName}</b> has not completed persona analysis <br /> please ask {targetUserDisplayName} to complete to see results</Typography>
                        <Box>
                            <Button variant="contained" onClick={() => navigate(-1)}>
                                back to friends
                            </Button>
                        </Box>
                    </Box>
                </StyledContainer>
            </>
        );
    }

    const keywords = targetUserPersona.coreValueVector.slice(0, 3).map((val) => val.key.toLocaleLowerCase()).concat(targetUserPersona.personalityTraitVector.slice(0, 3).map((val) => val.key.toLocaleLowerCase()));


    return (
        <>
            <TopBar
                title="Ipsum AI"
                navigations={
                    [
                        <DrawerNavigationBar />
                    ]
                } />
            <StyledContainer>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ p: 3, maxWidth: '900px' }}>
                    <Box>
                        {
                            <Box margin={2}>
                                <Typography variant="h4" fontFamily="'Fredericka the Great', cursive;">
                                    {targetUserPersona.title}
                                </Typography>
                                <Typography variant="subtitle1" pt={1}>
                                    {targetUserDisplayName} <Typography variant="caption">({userId?.slice(0, 5)})</Typography>
                                </Typography>
                            </Box>
                        }
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                        <CoreValueChart coreValueVector={targetUserPersona.coreValueVector} sort={true} shuffle={false} maxCount={6} width="160px" height="160px" fontSize={6} />
                        <PersonalityTraitVectorChart personalityTraitVector={targetUserPersona.personalityTraitVector} width="180px" height="180px" />
                    </Box>
                    <PersonaKeywords keywords={keywords} />
                    <PersonaDescription personaReport={targetUserPersona} />
                    <ProfileSurveyAnswers surveyResults={targetUserCompletedSurveys ?? []} handleShowSurveyContent={() => { }} />
                </Box>
                <Box mb={5}>
                    <Link
                        to={`${ROUTES.compare_user.path}/${userId}`}
                        onClick={(e) => logEvent({
                            category: "Friends",
                            action: "Click Friend Compare",
                        })}
                        style={{
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Button variant="contained">
                            Compare with me
                        </Button>
                    </Link>
                </Box>
            </StyledContainer>
        </>
    );
}

export default UserPage;
