import { Box, Button, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '../utils/Routes';
import { ArrowForwardIos, Error } from '@mui/icons-material';
import TopBar from '../navigations/TopBar';
import DrawerNavigationBar from '../navigations/DrawerNavigationBar';
import { useSurveyCollectionData } from '../context/SurveyCollectionDataContext';
import { logEvent } from '../utils/logging';
import AnimatedLogo from '../components/AnimatedLogo';
import { useTranslation } from 'react-i18next';
import CircularProgressBase from '../components/CircularProgressBase';


function SurveyEntryPage() {
    const { t } = useTranslation();
    const { surveyCollectionData, loading } = useSurveyCollectionData();
    const params = useParams();

    if (loading) {
        return (
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                alignItems='center'
                width='100%'
            >
                <CircularProgressBase isFullscreen={true} />
            </Box>
        )
    }

    const survey = surveyCollectionData.find((survey) => survey.key === params.type);

    if (!survey) {
        return (
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                alignItems='center'
                width='100%'
            >
                <TopBar
                    title="Survey"
                    navigations={
                        [
                            <DrawerNavigationBar />
                        ]
                    } />
                <Box display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    width="100%"
                    height="60vh">
                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" sx={{ m: 3 }}>
                        <Error color="info" />
                        <Typography sx={{ pl: 2 }}> Cannot find the page</Typography>
                    </Box>
                    <Link to={ROUTES.main.path} style={{ textDecoration: 'none' }}>
                        <Button variant="contained" color="primary">
                            go back to main page
                        </Button>
                    </Link>
                </Box>
            </Box>
        )
    }

    return (
        <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            alignItems='center'
            width='100%'
        >
            <TopBar
                title="Survey"
                navigations={
                    [
                        <DrawerNavigationBar />
                    ]
                } />
            <Box
                maxWidth="600px"
                display='flex'
                flexDirection='column'
                textAlign='center'
                p={4}
            >
                <Box mt={2}>
                    <Typography variant="h4" color="primary" p={2}>
                        {survey.title}
                    </Typography>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box sx={{ p: 1, mt: 2, mb: 2, textAlign: 'center' }}>
                            <Typography variant='body1' color="primary.dark" sx={{ whiteSpace: 'pre-line', textAlign: 'center' }} maxWidth="600px">
                                {survey.description}
                            </Typography>
                        </Box>
                        <Box p={2}>
                            <Button component={Link} to={ROUTES.survey_questions.path} variant="outlined" color="primary" sx={{ borderRadius: 8, p: 2, border: 'hidden', backgroundImage: 'linear-gradient(to bottom right, #63CFB160 0%, #2B9C32C0 100%)', color: 'white', minWidth: '200px' }} onClick={() => {
                                logEvent({
                                    category: "Survey",
                                    action: "Click Start Survey",
                                    label: survey.key,
                                })
                            }}>
                                {t("MainPage_StartSurvey")}
                                <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
                            </Button>
                        </Box>
                        <Box>
                            <Button component={Link} to={ROUTES.main.path} variant="outlined" color="info" sx={{ borderRadius: 8, p: 2, border: 'hidden', minWidth: '200px' }} onClick={() => {
                                logEvent({
                                    category: "Survey",
                                    action: "Click Explore Other Tests",
                                    label: survey.key,
                                })
                            }}>
                                {t("SurveyPage_ExploreOtherTests")}
                            </Button>
                        </Box>
                        <Box m={3} sx={{ opacity: 0.9 }}>
                            <AnimatedLogo clockwise={true} type="rotate" size="120px" />
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    );
}

export default SurveyEntryPage;
