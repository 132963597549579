import { Box, Typography } from '@mui/material';
import React from 'react';
import AnimatedLogo from './AnimatedLogo';

interface CircularProgressBaseProps {
    title?: string,
    isFullscreen: boolean,
}

const CircularProgressBase: React.FC<CircularProgressBaseProps> = ({ title, isFullscreen }) => {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" height={isFullscreen ? '80vh' : undefined} textAlign="center" m={2}>
            {title ? <Typography p={3}>{title}</Typography> : null}
            <AnimatedLogo clockwise={true} type={'flash'} size="130px" />
        </Box>
    );
};

export default CircularProgressBase;
