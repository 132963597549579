import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { CORE_VALUE_COLOR, PERSONALITY_TRAIT_COLOR, PREFERENCE_COLOR } from '../utils/chartUtils';

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
    padding: 30,
    flexGrow: 1,
});

function ColorsPage() {

    const personality_trait_colors = Object.keys(PERSONALITY_TRAIT_COLOR) as Array<keyof typeof PERSONALITY_TRAIT_COLOR>;
    const core_value_colors = Object.keys(CORE_VALUE_COLOR) as Array<keyof typeof CORE_VALUE_COLOR>;
    const preference_colors = Object.keys(PREFERENCE_COLOR) as Array<keyof typeof PREFERENCE_COLOR>;

    return (
        <>
            <StyledContainer>
                <Typography variant="h5" p={2}> Personality Traits </Typography>
                <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
                    {
                        personality_trait_colors.map((key) =>
                        (
                            <Box key={`personality_trait_${key}`} width="130px" margin={1} padding={2} borderRadius={2} sx={{ backgroundColor: PERSONALITY_TRAIT_COLOR[key] }}>
                                <Typography variant='caption'><b>{key}</b><br />{PERSONALITY_TRAIT_COLOR[key]}</Typography>
                            </Box>
                        ))
                    }
                </Box>
                <Typography variant="h5" p={2} mt={2}> Core Value </Typography>
                <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
                    {
                        core_value_colors.map((key) =>
                        (
                            <Box key={`core_value_${key}`} width="130px" margin={1} padding={2} borderRadius={2} sx={{ backgroundColor: CORE_VALUE_COLOR[key] }}>
                                <Typography variant='caption'><b>{key}</b><br />{CORE_VALUE_COLOR[key]}</Typography>
                            </Box>
                        ))
                    }
                </Box>
                <Typography variant="h5" p={2} mt={2}> Preference </Typography>
                <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
                    {
                        preference_colors.map((key) =>
                        (
                            <Box key={`preference_${key}`} width="130px" margin={1} padding={2} borderRadius={2} sx={{ backgroundColor: PREFERENCE_COLOR[key] }}>
                                <Typography variant='caption'><b>{key}</b><br />{PREFERENCE_COLOR[key]}</Typography>
                            </Box>
                        ))
                    }
                </Box>

            </StyledContainer >
        </>
    );
}

export default ColorsPage;
