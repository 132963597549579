import { Dispatch, SetStateAction, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { UserFriend, UserInfo, UserInfoSearchResult } from '../../utils/interfaces';
import { searchUsers, sendFriendRequest } from '../../api/friends_api';
import { User } from '@firebase/auth';
import { logEvent } from '../../utils/logging';
import { GRADIENT_COLORS } from '../../const/gradientColors';

interface FriendAddComponentProps {
    user: User,
    userInfo: UserInfo,
    friends: UserFriend[],
    pendingFriendsIDs: string[],
    setPendingFriendsIDs: Dispatch<SetStateAction<string[]>>,
}

const FriendAddComponent: React.FC<FriendAddComponentProps> = ({ user, userInfo, friends, pendingFriendsIDs, setPendingFriendsIDs }) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState<UserInfoSearchResult[]>([]);
    const [error, setError] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleSearchSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError('');
        if (!user) return;
        let results = await searchUsers(user.uid, searchValue);
        results = results.filter(result => !friends.some(friend => friend.userId.includes(result.userId)));
        setSearchResults(results);
        logEvent({
            category: "Friends",
            action: "Click Friends Search",
        })
    };

    const handleAddFriend = async (friendId: string, friendName: string) => {
        if (!user) return;
        try {
            setError('');
            await sendFriendRequest(user.uid, userInfo.displayName, friendId, friendName);
            setPendingFriendsIDs([...pendingFriendsIDs, friendId]);
            logEvent({
                category: "Friends",
                action: "Click Add Friend",
            })
        } catch (error) {
            setError(`Error adding friend:\n${error}`);
            logEvent({
                category: "Friends",
                action: "Click Add Failed",
            })
        }
    };

    return (
        <Box m={2} mb={0} width="100%" display="flex" flexDirection="column" alignItems="center">
            <Box m={2} mb={0} width="100%" flexGrow={1} maxWidth="700px">
                <Box display="flex" pl={4} >
                    <Typography variant="subtitle1">Add Friends</Typography>
                </Box>
                <form onSubmit={handleSearchSubmit}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexGrow={1} m={3} mt={1}>
                        <TextField label="Enter ID or User Name" value={searchValue} onChange={handleSearchChange} sx={{ flexGrow: 1, mr: 2 }} />
                        <Button type="submit" variant="contained" disabled={!searchValue}>
                            Search
                        </Button>
                    </Box>
                </form>
                {searchResults.length !== 0 ?
                    <><Box width="100%" display="flex" justifyContent="center">
                        <Box p={2} maxWidth="400px" flexGrow={1}>
                            {searchResults.map((user) => (
                                <Box key={user.userId} mb={2}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" p={2} borderRadius={3} sx={{ border: '1px solid #8b95b140', backgroundImage: GRADIENT_COLORS.pastel_main }}>
                                        <Box display="flex" flexDirection="column" alignItems="flex-start" p={2}>
                                            <Typography variant="subtitle1">{user.displayName}</Typography>
                                            <Typography variant="caption">{user.title ?? 'unknown'}</Typography>
                                        </Box>
                                        {pendingFriendsIDs.find((friendid) => friendid === user.userId)
                                            ? <Button variant="outlined" disabled>
                                                PENDING
                                            </Button>
                                            : <Button variant="outlined" onClick={() => handleAddFriend(user.userId, user.displayName)}>
                                                Add Friend
                                            </Button>
                                        }
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                        {error && <div style={{ color: 'red' }}>{error}</div>}
                    </>
                    : null}
            </Box>
        </Box >
    );
};

export default FriendAddComponent;
