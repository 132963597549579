import { Box, Typography, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { SurveyResponseType } from '../../utils/interfaces';
import { useSurveyCollectionData } from '../../context/SurveyCollectionDataContext';

interface ProfileDialogType {
    selectedSurveyResult: SurveyResponseType | null,
    openDialog: boolean,
    handleCloseDialog: () => void,
}

const ProfileDialog: React.FC<ProfileDialogType> = ({ selectedSurveyResult, openDialog, handleCloseDialog }) => {
    const { surveyCollectionData, loading } = useSurveyCollectionData();
    if (loading || !surveyCollectionData || !selectedSurveyResult) {
        return null;
    }
    return (
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="sm"
            fullWidth
            sx={{ overflow: 'scroll' }}
        >
            <Box sx={{
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                    content: `""`,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: surveyCollectionData ? `url(${surveyCollectionData.find((survey) => survey.key === selectedSurveyResult?.key)?.backgroundImageSrc})` : '',
                    backgroundRepeat: 'repeat',
                    backgroundSize: 'cover',
                    opacity: 0.1,
                    overflow: 'scroll',
                },
            }}>
                <Box display="flex" flexDirection="column" justifyContent='center' alignItems="center" sx={{ p: 3, maxWidth: '700px', height: '80%', overflow: 'scroll', position: 'relative', textAlign: 'justify' }}>
                    <DialogTitle>
                        <Typography sx={{ whiteSpace: 'pre-line' }}>
                            {selectedSurveyResult.title}
                        </Typography>
                        <Typography variant="caption" color="grey" sx={{ whiteSpace: 'pre-line' }} >
                            {selectedSurveyResult.subtitle}
                        </Typography>
                        <Divider />
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="caption" sx={{ whiteSpace: 'pre-line', }} >
                            {selectedSurveyResult.reason}
                        </Typography>
                    </DialogContent>
                </Box>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>)
}

export default ProfileDialog