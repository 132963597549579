import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AnimatedLogo from './AnimatedLogo';

const wittyTexts = [
    "Mining for golden personality traits...",
    "Weaving the tapestry of your values...",
    "Unearthing hidden preferences...",
    "Gardening your unique character...",
    "Assembling your values puzzle...",
    "Sculpting the clay of your personality...",
    "Fishing for the pearls of your taste...",
    "Harvesting the garden of your traits...",
    "Illuminating the constellation of your values...",
    "Tuning in to the melody of your preferences..."
];


const CircularProgressWithText: React.FC = () => {

    const [currentText, setCurrentText] = useState(wittyTexts[0]);

    useEffect(() => {
        const changeText = () => {
            const index = Math.floor(Math.random() * wittyTexts.length);
            setCurrentText(wittyTexts[index]);
        };
        const interval = setInterval(changeText, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" textAlign="center" m={2}>
            <Typography variant="subtitle2" p={3} pb={5}>{currentText}</Typography>
            <AnimatedLogo clockwise={true} type={'flash'} size="130px" />
            <Typography variant="caption" fontSize="8px" p={5}>process could take up to 30 seconds<br /> please do not close browser</Typography>
        </Box >
    );
};

export default CircularProgressWithText;
