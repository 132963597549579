export type GradientColorType = {
    name: string,
    value: string
}

export const GRADIENT_COLORS = {
    'pastel_yellow': 'linear-gradient(to bottom right, #f7efd8 0%, #F7F7F0 100%)',
    'pastel_cream': 'linear-gradient(to bottom right, #f7ebe8 0%, #F7F7F0 100%)',
    'pastel_lime': 'linear-gradient(to bottom right, #d1ffb1 0%, #F7F7F0 100%)',
    'pastel_sage': 'linear-gradient(to bottom right, #d1d8b7 0%, #F7F7F0 100%)',
    'pastel_green': 'linear-gradient(to bottom right, #b8d8ba 0%, #F7F7F0 100%)',
    'pastel_turquoise': 'linear-gradient(to bottom right, #a5dee5 0%, #F7F7D4 100%)',
    'pastel_blue': 'linear-gradient(to bottom right, #aed9e0 0%, #F7F7F0 100%)',
    'pastel_lavender': 'linear-gradient(to bottom right, #e2c2ff 0%, #F7F7E0 100%)',
    'pastel_purple': 'linear-gradient(to bottom right, #dac3dc 0%, #F7F7F0 100%)',
    'pastel_coral': 'linear-gradient(to bottom right, #ffb6b9 0%, #F7F7F0 100%)',
    'pastel_salmon': 'linear-gradient(to bottom right, #ffc4a3 0%, #F7F7F0 100%)',
    'pastel_amber': 'linear-gradient(to bottom right, #ffd0ae 0%, #F7F7F0 100%)',
    'pastel_lavender_gray': 'linear-gradient(to bottom right, #c4c4c4 0%, #F7F7F0 100%)',
    'pastel_main': 'linear-gradient(to bottom right, #8b95b160 0%, #F7F7F0 100%)',
    'pastel_secondary': 'linear-gradient(to bottom right, #587B7750 0%, #F7F7F0 100%)',
    'pastel_main_light': 'linear-gradient(to bottom right, #8b95b120 0%, #F7F7F0 100%)',
    'pastel_secondary_light': 'linear-gradient(to bottom right, #587B7720 0%, #F7F7F0 100%)',
    'pastel_similarities': 'linear-gradient(to bottom right, #05905420 0%, #F7F7F0 100%)',
    'pastel_differences': 'linear-gradient(to bottom right, #E34E4520 0%, #F7F7F0 100%)',
};
