import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ROUTES from '../utils/Routes';
import TopBar from '../navigations/TopBar';
import DrawerNavigationBar from '../navigations/DrawerNavigationBar';
import { useTranslation } from 'react-i18next';
import { ArrowForwardIos, CheckCircle } from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';
import { GRADIENT_COLORS } from '../const/gradientColors';
import { logEvent } from '../utils/logging';
import CoreValueChart from '../components/charts/CoreValueChart';
import PersonalityTraitVectorChart from '../components/charts/PersonalityTraitVectorChart';
import { SAMPLE_CORE_VALUES_VECTOR, SAMPLE_PERSONALITY_TRAIT_VECTOR, SAMPLE_QUOTES, SAMPLE_USER_COMPARISON_DATA } from '../const/sampleData';
import PersonaKeywords from '../components/profile/PersonaKeywords';
import AnimatedLogo from '../components/AnimatedLogo';
import { useSurveyCollectionData } from '../context/SurveyCollectionDataContext';

const StyledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'center',
    margin: 10,
    flexGrow: 1,
    minHeight: '80vh',
    maxWidth: '600px',
});

function Main() {
    const { t } = useTranslation();
    const { surveyCollectionData } = useSurveyCollectionData();
    const { completedSurveys } = useAuth();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const logEntry = (e: React.MouseEvent, survey_name: string) => {
        logEvent({
            category: "Survey",
            action: "Click Select Survey",
            label: survey_name,
        })
    }
    const colors = Object.values(GRADIENT_COLORS).sort(() => 0.5 - Math.random());

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <TopBar title="Ipsum AI" navigations={[<DrawerNavigationBar />]} />
            <StyledContainer>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding={3} paddingTop={3} maxWidth="700px">
                    <Typography maxWidth="500px" variant="h5" color="primary" p={3}>
                        "{t("MainPage_Title")}"
                    </Typography>
                    <Typography variant="body1" color="primary" p={2} mb={2}>
                        {t("MainPage_Subtitle")}
                    </Typography>
                    <Link
                        to={`${ROUTES.survey.path}/fictional_character`}
                        onClick={(e) => logEntry(e, 'main')}
                        style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                    >
                        <Button variant="outlined" sx={{ borderRadius: 8, p: 2, border: 'hidden', backgroundImage: 'linear-gradient(to bottom right, #63CFB160 0%, #5D9EB1F0 60%, #0569D0F0 100%)', color: 'white', minWidth: '200px' }}>
                            {t("MainPage_Instruction")}
                            <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
                        </Button>
                    </Link>
                    <Box margin={6}>
                        <AnimatedLogo clockwise={true} type="rotate" size="150px" />
                    </Box>
                </Box>
                <Box key="surveyContainer" mt={4}>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" textAlign="left">
                        <Typography ml={2} mb={1} variant="h4" fontWeight={500} color="primary.dark">{t('MainPage_TakeSurveyTitle')} </Typography>
                        <Typography ml={2} mb={1} pr={1} pt={1} pb={1} variant="body1" fontWeight={500} color="primary">{t('MainPage_TakeSurveySubtitle')} </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {surveyCollectionData.map((survey, index) => {
                            const offsetIndex = index % 2 !== 0;
                            const isSurveyComplete = completedSurveys.find((completedSurvey) => completedSurvey.key === survey.key);
                            return (
                                <Box
                                    key={survey.key}
                                    display="flex"
                                    margin={1}
                                    flexDirection="column"
                                    flexGrow={1}
                                    justifyContent="center"
                                    alignItems={offsetIndex ? 'flex-start' : 'flex-end'}
                                    p={1}
                                    pt={3}
                                    sx={{
                                        borderRadius: 5,
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            backgroundColor: '#f5f5f5',
                                            opacity: 1,
                                        },
                                        animation: offsetIndex
                                            ? 'slide-left 0.5s ease-out forwards'
                                            : 'slide-right 0.5s ease-out forwards',
                                        animationDelay: `${index * 0.1}s`,
                                        background: isSurveyComplete ? 'linear-gradient(to bottom left, #b8d8ba 0%, #FFF 100%)' : colors[index % surveyCollectionData.length],
                                        opacity: isSurveyComplete ? 0.5 : 1,
                                    }}
                                >
                                    <Box display="flex"
                                        flexDirection={offsetIndex ? 'row-reverse' : 'row'}
                                    >
                                        <Box
                                            width="120px"
                                            height="120px"
                                            marginRight={1}
                                            sx={{
                                                backgroundImage: `url(${survey.backgroundImageSrc})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                borderRadius: 5,
                                                flexShrink: 0,
                                            }}
                                        />
                                        <Box
                                            textAlign={'start'}
                                            pl={1}
                                            pr={1}
                                            flexGrow={1}
                                            sx={{
                                                flexShrink: 1,
                                            }}
                                        >
                                            <Box>
                                                <Box>
                                                    <Typography color="primary.dark" fontWeight={600} variant="subtitle2">{survey.title}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography color="primary" variant="caption">
                                                        {survey.headline}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent={offsetIndex ? 'flex-start' : 'flex-end'}
                                        flexDirection={offsetIndex ? 'row-reverse' : 'row'}
                                        pl={1}
                                        pr={1}
                                    >
                                        {isSurveyComplete ? <CheckCircle color="success" sx={{ m: 1 }} /> : <CheckCircle color="disabled" sx={{ m: 1 }} />}
                                        <Link
                                            to={`${ROUTES.survey.path}/${survey.key.toString()}`}
                                            onClick={(e) => logEntry(e, survey.key)}
                                            style={{
                                                textDecoration: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                sx={{ borderRadius: 4, fontSize: 10 }}
                                            >
                                                {isSurveyComplete ? t("MainPage_StartSurveyAgain") : t("MainPage_StartSurvey")}
                                                <ArrowForwardIos
                                                    sx={{ fontSize: '0.5rem', marginLeft: '0.25rem' }}
                                                />
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    <Box m={1}>
                        <Typography variant="subtitle2" color="primary">
                            {completedSurveys.length} / {surveyCollectionData.length} {t('MainPage_TestCompleted')}
                        </Typography>
                    </Box>
                </Box>
                <Box key="personaContainer" mt={7}>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" textAlign="left">
                        <Typography ml={2} mb={1} variant="h4" fontWeight={500} color="primary.dark">{t('MainPage_AnalyzePersonaTitle')} </Typography>
                        <Typography ml={2} mb={1} pr={1} pt={1} pb={1} variant="body1" fontWeight={500} color="primary">{t('MainPage_AnalyzePersonaSubtitle')} </Typography>
                    </Box>
                    <Box m={1} p={2} borderRadius={5} sx={{ backgroundImage: 'linear-gradient(to bottom right, #aed9e090 0%, #FFF 80%)' }}>
                        <Box mt={2} mb={1}>
                            <Box mt={2} mb={3}>
                                <Typography variant="h5" fontWeight={500} fontFamily="'Fredericka the Great', cursive;">
                                    Proactive Visionary
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" m={1}>
                                    <Box>
                                        <CoreValueChart coreValueVector={SAMPLE_CORE_VALUES_VECTOR} sort={true} shuffle={false} maxCount={6} width="150px" height="150px" fontSize={6} />
                                        <Typography variant="caption" color="ActiveBorder">Core Values</Typography>
                                    </Box>
                                    <Box>
                                        <PersonalityTraitVectorChart personalityTraitVector={SAMPLE_PERSONALITY_TRAIT_VECTOR} width="150px" height="150px" />
                                        <Typography variant="caption" color="ActiveBorder" mb={2}>Personality Traits</Typography>
                                    </Box>
                                </Box>
                                <Box p={2}>
                                    {
                                        SAMPLE_QUOTES.map((quote, index) => {
                                            return (
                                                <Box key={`quote_${index}`}>
                                                    <Typography variant="caption" sx={{ fontSize: 10, fontWeight: 500 }}>
                                                        "{quote}"
                                                    </Typography>
                                                </Box>
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                        </Box>
                        <Box m={3}>
                            {completedSurveys.length < 1 ?
                                <Button component={Link} to={ROUTES.profile.path} variant="outlined" color="primary" sx={{ borderRadius: 3 }} disabled>
                                    {t("MainPage_AnalyzePersona")}
                                    <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
                                </Button> :
                                <Button component={Link} to={ROUTES.profile.path} variant="outlined" color="primary" sx={{ borderRadius: 3 }}>
                                    {t("MainPage_AnalyzePersona")}
                                    <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
                                </Button>
                            }
                        </Box>
                    </Box>
                </Box>
                <Box key="friendsContainer" mt={9}>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" textAlign="left">
                        <Typography ml={2} mb={1} variant="h4" fontWeight={500} color="primary.dark">{t('UserComparePage_Title')} </Typography>
                        <Typography ml={2} mb={1} pr={1} pt={1} pb={1} variant="body1" fontWeight={500} color="primary">{t('UserComparePage_Subtitle')} </Typography>
                    </Box>
                    <Box m={1} p={2} borderRadius={5} sx={{ backgroundImage: 'linear-gradient(to bottom right, #ffc4a340 0%, #FFF 80%)' }}>
                        <Box display="flex" flexDirection="row" width="100%">
                            <Grid container spacing={2}>
                                {
                                    SAMPLE_USER_COMPARISON_DATA.data.map(({ name, persona, vVec, pVec, keywords }, index) => {
                                        return (
                                            <Grid key={`grid_index_${index}`} item xs={6} md={6} lg={6}>
                                                <Box key={name} display="flex" justifyContent="center" sx={{ pr: 2, borderRight: index === 0 ? '1px solid #849AAC80' : undefined }}>
                                                    <Box display="flex" flexDirection="column" alignItems="center">
                                                        <Box display="flex" flexDirection="column" m={2}>
                                                            <Typography variant={isSmallScreen ? "h5" : "h4"} fontFamily="'Fredericka the Great', cursive;" color="primary.dark" fontWeight={550}>
                                                                {persona.title}
                                                            </Typography>
                                                            <Typography pt={1} variant={isSmallScreen ? "caption" : "subtitle2"} color="primary">
                                                                ({name})
                                                            </Typography>
                                                        </Box>
                                                        <CoreValueChart coreValueVector={vVec} sort={true} shuffle={false} maxCount={6} width="160px" height="160px" fontSize={6} />
                                                        <PersonalityTraitVectorChart personalityTraitVector={pVec} width="160px" height="160px" />
                                                        <PersonaKeywords keywords={keywords} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                        <Box m={1} display="flex" flexDirection="column" justifyContent="center" maxWidth="700px">
                            <Box p={3} mt={1} sx={{ backgroundImage: GRADIENT_COLORS.pastel_similarities, borderRadius: 5 }}>
                                <Typography variant="subtitle2" fontWeight={600} color="#05905480">
                                    {t('UserComparePage_Similarities')}
                                </Typography>
                                <Box>
                                    {
                                        SAMPLE_USER_COMPARISON_DATA.comparisonResult.similarities.map((similarity, index) => {
                                            return <Box key={`similarity_${index}`} m={0.5}><Typography variant="caption" fontSize={9}>{similarity}<br /></Typography></Box>
                                        })
                                    }
                                </Box>
                            </Box>
                            <Box p={3} mt={2} sx={{ backgroundImage: GRADIENT_COLORS.pastel_differences, borderRadius: 5 }}>
                                <Typography variant="subtitle2" fontWeight={600} color="#E34E4570">
                                    {t('UserComparePage_Differences')}
                                </Typography>
                                <Box>
                                    {
                                        SAMPLE_USER_COMPARISON_DATA.comparisonResult.differences.map((difference, index) => {
                                            return <Box key={`difference_${index}`} m={0.5}><Typography variant="caption" fontSize={9}>{difference}<br /></Typography></Box>
                                        })
                                    }
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={3} mb={2}>
                            <Button component={Link} to={ROUTES.friends.path} variant="outlined" color="primary" sx={{ borderRadius: 3 }}>
                                {t("UserComparePage_Button")}
                                <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box m={5} sx={{ opacity: 0.7 }}>
                    <AnimatedLogo clockwise={false} type="none" size="150px" />
                </Box>
                <Box sx={{ position: 'relative', bottom: 0, left: 0, right: 0, padding: 5 }} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Typography variant="caption" sx={{ fontSize: '8px' }}>
                        {t("MainPage_Copyright")}
                    </Typography>
                    <Box>
                        <Button component={Link} to={ROUTES.privacy.path} color="primary" sx={{ fontSize: '8px' }}>
                            {t("MainPage_PrivacyPolicy")}
                        </Button>
                        <Button component={Link} to={ROUTES.terms.path} color="primary" sx={{ fontSize: '8px' }}>
                            {t("MainPage_Terms")}
                        </Button>
                    </Box>
                </Box>
            </StyledContainer>
        </Box>
    );
}

export default Main;
